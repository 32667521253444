<template>
  <div class="loader-container">
    <span class="loader" :isLarge="isLarge"></span>
  </div>
</template>

<script>
export default {
  props: {
    isLarge: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.loader {
  margin: auto;
  border: 2px solid #EAF0F6;
  border-radius: 50%;
  border-top: 2px solid #1f314d;
  width: 1rem;
  height: 1rem;
  animation: spinner .5s linear infinite;
}

.loader[isLarge] {
  width: 6rem;
  height: 6rem;
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
