<template>
  <div v-if="hasAllPermissionsWithRequiredLevels">
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'HasPermissions',
  emits: ['has-permissions'],
  props: {
    permissions: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState(['clientSession']),
    hasAllPermissionsWithRequiredLevels () {
      let hasAllPermissions = true // start with true, if any permission is missing, set to false

      for (let i = 0; i < this.permissions.length; i++) {
        let permission = this.permissions[i]

        let permissionBeingChecked = this.clientSession.permissions.find(x => x.code === permission[0])
        if (!permissionBeingChecked) {
          hasAllPermissions = false
          break
        }

        // figure out if we have the permission level required to access the resource
        if (permissionBeingChecked.access < permission[1]) {
          hasAllPermissions = false
          break
        }
      }

      return hasAllPermissions
    }
  }
}
</script>
