<template>
  <div v-show="(display && carriers) || isMenuItemType('link')" :type="item.data.ItemType">
    <!-- <div v-if="isLoading">
      <menu-item-loader :level="level + 1" :menuItem="item" />
    </div> -->

    <div v-if="isMenuItemType('group') && isValidGroup">
      <menu-item-folder
        :item="item"
        :level="level"
        :open="isOpen"
        :sidemenu="sidemenu"
        :tooltipConfig="tooltipConfig"
        :isLoad="isLoading"
        @onOpenChange="onOpenChange"
        :class="isLoading ? 'menuitem-loading' : ''"
        type="group"
      />

      <menu-item-folder-dropdown
        v-if="isOpen"
        :item="item"
        :level="level"
        :reportCodeCounts="reportCodeCounts"
        :carriers="carriers"
        :activeSite="activeSite"
        :activeUser="activeUser"
        :open="isOpen"
        :isLoad="isLoading"
        :years="{
          menuItemId: item.id,
          years: years
        }"
        @years="onYearsUpdate"
      />
    </div>

    <div v-else-if="isMenuItemType('dynamic Carrier') && carrierIsvalid()">
      <div
        :class="['sidemenu-mask-dropdown', isLoading ? 'menuitem-loading' : '']"
        @click="($event) => (isOpen = !isOpen)"
        @mouseover="($event) => ellipsis($event, true)"
        @mouseleave="($event) => ellipsis($event, false)"
        :id="item.id"
        :children="numCarriers"
        :parent="item.data.ParentId"
        :ref="item.id"
      >
        <div
          class="tooltip-hover"
          :open="false"
          v-tooltip.top-center="{
            content: item.data.Label,
            trigger: 'manual',
            boundariesElement: sidemenu,
          }"
        >
          <i
            :class="[
              'status-icon fa fa-chevron-right',
              isOpen ? 'open' : 'closed',
            ]"
          ></i>
          <i :class="getIcon(item.data)"></i>
          {{ item.data.Label }}
        </div>
      </div>

      <div
        class="sidemenu-mask-dropdown-list"
        :data-controlled-by="item.id"
        :open="isOpen"
        :level="getNextLevel() - 2"
        :type="item.data.ReportCode"
        :ref="item.id"
      >
        <div v-for="carrier in validCarriers" :key="carrier.code">
          <SidemenuCarrierItem
            :reportTypeCodes="reportTypeCodes"
            :activeSite="activeSite"
            :activeUser="activeUser"
            :carriers="carriers"
            :reportCodeCounts="reportCodeCounts"
            :level="getNextLevel()"

            v-bind:item="carrier"
            @update="update"
            @is-invalid="handleEvent(-1)"
            @is-valid="handleEvent(1)"
          />
        </div>
      </div>
    </div>

    <div v-else-if="isMenuItemType('vendor')">
      <menu-item-folder
        :item="item"
        :level="level"
        :open="isOpen"
        :sidemenu="sidemenu"
        :tooltipConfig="tooltipConfig"
        @onOpenChange="onOpenChange"
      />

      <menu-item-folder-dropdown
        v-if="isOpen"
        :item="item"
        :level="level"
        :reportCodeCounts="reportCodeCounts"
        :carriers="carriers"
        :activeSite="activeSite"
        :activeUser="activeUser"
        :open="isOpen"
        :isLoad="isLoading"
        :years="{
          menuItemId: item.id,
          years: years
        }"
        @years="onYearsUpdate"
      />
    </div>

    <div v-show="item.isLink && ((item.linkRequiresPermission && hasPermission('USR')) || !item.linkRequiresPermission)">
      <div
      :class="['sidemenu-mask-dropdown', isLoading ? 'menuitem-loading' : '']"
        @click.stop="navigate(item.data)"
        @mouseover="ellipsis($event, true)"
        @mouseleave="ellipsis($event, false)"
        :id="item.id"
        :children="5"
        :parent="item.data.ParentId"
        :ref="item.id"
      >
        <div class="tooltip-hover" :open="isOpen" v-tooltip.top-center="{ content: item.data.Label, trigger: 'manual', boundariesElement: sidemenu }">
            <i :class="getIcon(item.data)"></i>
            {{ item.data.Label }}
          </div>
      </div>
    </div>

    <div v-if="isMenuItemType('reports')">
      <menu-item-loader v-if="!reports && !isEmpty" :level="level + 1" :menuItem="item" />
      <menu-item-reports v-else-if="reports && !isEmpty"
        :reports="reports"
        :menuItem="item"
      />
      <div v-else>
        <div
          class="sidemenu-mask-dropdown-list"
          :open="true"
          :level="getNextLevel()"
        >
          <div class="sidemenu-mask-item" :level="level + 1">
              No files found
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'underscore'

import Constants from '../constants'
// import MenuItemYears from './MenuItemYears'
import SidemenuCarrierItem from './partials/SidemenuCarrierItem'
import MenuItemFolder from './MenuItemFolder'
import MenuItemLoader from './MenuItemLoader'
import MenuItemFolderDropdown from './MenuItemFolderDropdown'
import MenuItemReports from './MenuItemReports.vue'

import { mapGetters } from 'vuex'
import { activeLayout } from '../vuex-actions'

//
export default {
  name: 'MenuItem',
  props: {
    item: {
      children: Array,
      data: Object,
      id: Number,
      isStatic: Boolean,
      level: Number,
      reportCodes: Array,
      reports: Array,
      carrierCode: String
    },
    isLoad: Boolean,
    activeSite: Object,
    activeUser: Object,
    reportTypeCodes: Array,
    level: Number,
    carrierCode: {
      required: false,
      type: String
    },
    carriers: {
      type: Array,
      default: function () {
        return []
      }
    },
    open: Boolean,
    reportCodeCounts: Object
  },
  components: {
    // MenuItemYears,
    SidemenuCarrierItem,
    MenuItemFolder,
    MenuItemLoader,
    MenuItemFolderDropdown,
    MenuItemReports
  },
  data () {
    return {
      isOpen: false,
      sidemenu: document.querySelector('.sidemenu-mask'),
      reportCodes: {},
      years: {},
      reports: null,
      display: true,
      numCarriers: 0,
      isValid: true,
      isLoading: false,
      isEmpty: false
    }
  },
  watch: {
    isLoading (isLoad) {
      this.$emit('is-load', isLoad)
    },
    isOpen (isOpen) {
      if (isOpen) {
        this.isLoading = true

        this.getReports(() => {
          this.display = true
          this.$emit('is-valid')
          this.isLoading = false
        })
      }
    }
  },
  computed: {
    ...mapGetters([activeLayout]),
    itemYears () {
      return this.years
    },
    tooltipConfig () {
      return {
        content: this.item.data.Label,
        trigger: 'manual',
        boundariesElement: this.sidemenu
      }
    },
    isValidGroup () {
      const childrenHasLinks = this.item.children.filter(child => child.isLink).length > 0
      return (this.item.reportCodes.length > 0 || this.item.children.length > 0 || childrenHasLinks)
    },
    carrierReportCodesHasContent () {
      return true
    },
    validCarriers () {
      let that = this
      let carriers = []

      this.reportCodeCounts.carriers.forEach(function (_reportType) {
        if (!carriers.includes(_reportType.carrierCode)) {
          carriers.push(_reportType.carrierCode)
        }
      })

      return this.carriers.filter(function (_carrier) {
        if (carriers.includes(_carrier.code)) {
          _carrier.template = _.map(that.item.children, _.clone)
          return _carrier
        }
      })

      // let validCarriers = []

      // this.carriers.forEach(carrier => {
      //   if (this.reportCodeCounts.carriers.find(c => c.carrierCode === carrier.code)) {
      //     validCarriers.push(carrier)
      //   }
      // })

      // return validCarriers
    },
    isIE () {
      var ua = navigator.userAgent
      return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1
    },
    isYears () {
      return (
        this.years !== null && this.years.length !== 0 && !this.item.isStatic
      )
    },

    userHasAccessToBankUploads () {
      if (
        this.item.data.Route === 'BankUploads' &&
        !this.reportCodes.includes('USR')
      ) {
        return false
      }

      return true
    },
    isOSR () {
      return this.activeLayout === 'osr-facing'
    },
    padding () {
      let padding = 17 * this.level - 17 / 2

      if (this.isIE) {
        let pixels = padding - 17 / 2
        return {
          paddingLeft: `calc(2.6rem + ${pixels}px)`
        }
      }

      return {
        paddingLeft: padding + 25 + 'px'
      }
    }
  },
  created () {
    if (this.reportTypeCodes !== undefined) {
      localStorage.setItem('codes', JSON.stringify(this.reportTypeCodes))
      this.reportCodes = JSON.parse(JSON.stringify(this.reportTypeCodes))
    } else {
      this.reportCodes = JSON.parse(localStorage.getItem('codes'))
    }
  },
  mounted () {
    const canRenderBasedOnCodesAndPermissions = this.shouldRenderMenuItemBasedOnCountsAndPermissions()

    if (!canRenderBasedOnCodesAndPermissions && !this.isMenuItemType('vendor') && !this.isMenuItemType('carrier') && !this.isMenuItemType('link')) {
      this.display = false
      this.$emit('is-invalid')
      return
    }

    if (this.isMenuItemType('carrier')) {
      if (!this.carrierIsvalid()) {
        this.display = false
        this.$emit('is-invalid')
        // this.isLoading = true

        // this.getReports(() => {
        //   this.isLoading = false
        //   this.display = true
        //   this.isLoading = false
        //   this.display = true
        //   this.$emit('is-valid')
        // })
      } else {
        this.display = true
        this.$emit('is-valid')
      }
    }

    // Make sure this is a menuItem with a report code that is a document which is not a BCC (vendor) document and actually has documents.
    if (
      this.item.code &&
      this.isMenuItemType('carrier') && // Added isCarrier check to prevent bleeding this logic into vendor docs. TODO:// determine if this is the correct logic.
      this.item.isDocument &&
      this.item.carrierCode !== 'BCC'
    ) {
      this.$emit('is-invalid')
      this.display = false
    }

    /**
     * This is a static report type that is shown inline with the folder and
     * not broken down into years. Additionally, we should only render if the
     * user has access to the report type and the report type has documents.
     */
    if (
      this.isMenuItemType('reports') &&
      this.item.isStatic &&
      !canRenderBasedOnCodesAndPermissions
    ) {
      this.display = false
      this.$emit('is-invalid')
    }

    // if vendor grouping
    if (this.item.isVendor && !this.vendorIsValid()) {
      this.$emit('is-invalid')
      this.display = false
    }

    if (
      this.isMenuItemType('reports') &&
      !this.isMenuItemType('carrier')
    ) {
      this.isLoading = true

      this.getReports(() => {
        this.isLoading = false
        this.display = true
        this.isLoading = false
        this.$emit('is-valid')
      })
    }

    if (this.isMenuItemType('link')) {
      this.display = true
      this.$emit('is-valid')
    }
  },
  methods: {
    hasPermission (code) {
      return this.reportCodes.includes(code)
    },
    shouldRenderMenuItemBasedOnCountsAndPermissions () {
      let key = ''
      let validChildrenNumber = 0
      let codesInQuestion = []
      const item = this.item
      const isCarrier = this.isMenuItemType('carrier') || this.item.isCarrier
      const isVendor = this.isMenuItemType('vendor') || this.item.isVendor

      if (isCarrier) {
        codesInQuestion = item.carrierCodes
        key = 'carrier'
        return true
      }

      if (isVendor) {
        codesInQuestion = item.vendorCodes
        key = 'vendor'
        return true
      }

      if (!isCarrier && !isVendor) {
        codesInQuestion = item.reportCodes
        key = 'reports'
      }

      // TODO:// Missing logic for documents.... We need re-determine how this is found as it seems to have been lost in the refactor.
      let permissions = this.reportCodes
      codesInQuestion.forEach(code => {
        let reportCodeCounts = this.reportCodeCounts[key]
        let reportCodeCount = reportCodeCounts.find(c => c.typeCode === code)
        if (reportCodeCount && reportCodeCount.count > 0 && permissions.includes(code)) {
          validChildrenNumber++
        }
      })

      const checkChildrenForLinks = (children) => {
        children.forEach(child => {
          if (child.isLink && !child.linkRequiresPermission) {
            validChildrenNumber++
          }

          if (child.children) {
            checkChildrenForLinks(child.children)
          }
        })
      }

      checkChildrenForLinks(item.children)

      return validChildrenNumber > 0
    },
    onOpenChange (isOpen) {
      this.isOpen = isOpen

      if (this.isOpen) {
        this.isLoading = true

        this.getReports(() => {
          this.display = true
          this.$emit('is-valid')
          this.isLoading = false
        })
      } else {
        this.years = {} // We want to re-check anytime they open the menu.
      }
    },
    onYearsUpdate (years) {
      this.years = years
    },

    isMenuItemType (type) {
      let types = Constants.menu.type

      return parseInt(this.item.data.ItemType) === types[type]
    },

    groupHasTypeCodeReports () {
      let hasReports = false

      let reportCodes = this.item.reportCodes ? this.item.reportCodes : []

      if (this.isMenuItemType('vendor')) {
        this.reportCodeCounts.vendors.forEach(vendorType => {
          if (reportCodes.includes(vendorType.typeCode)) {
            hasReports = true
          }
        })
      } else {
        this.reportCodeCounts.vendors.forEach(reportType => {
          if (reportCodes.includes(reportType.typeCode)) {
            hasReports = true
          }
        })
      }

      return hasReports
    },
    carrierHasTypeCodeDocuments () {
      if (
        this.item.data.ReportCode &&
        this.item.isDocument &&
        this.item.carrierCode !== 'BCC'
      ) {
        let Code = this.item.data.ReportCode
        let CarrierCode = this.item.carrierCode
        let hasCodeDocuments = false

        this.reportCodeCounts.carriers.map((_typeCode) => {
          if (
            Code === _typeCode.typeCode &&
            _typeCode.carrierCode === CarrierCode
          ) {
            hasCodeDocuments = true
          }
        })
        return hasCodeDocuments
      }

      return true
    },
    appendIsDocument (child) {
      let that = this

      child.isDocument = true
      child.carrierCode = 'BCC' // Override and set as BCC if this is the vendor list

      if (child.children.length > 0) {
        child.children.forEach(function (_child) {
          that.appendIsDocument(_child)
        })
      }
    },
    vendorIsValid () {
      let hasAtleastOne = false

      this.reportCodeCounts.vendors.forEach(vendor => {
        let hasPermission = this.reportCodes.includes(vendor.typeCode)

        if (this.item.reportCodes.includes(vendor.typeCode) && parseInt(vendor.count) > 0 && hasPermission) {
          hasAtleastOne = true
        }
      })

      return hasAtleastOne
    },
    navigate (menuItem) {
      if (this.isIE && menuItem.Route === 'BankUploads') {
        this.$router.push({ name: 'IEBankUploads' })
      } else {
        this.$router.push({ name: menuItem.Route })
      }
    },
    carrierIsvalid () {
      let hasAtleastOne = false

      this.reportCodeCounts.carriers.forEach(carrier => {
        let hasPermission = this.reportCodes.includes(carrier.typeCode)

        if (this.item.reportCodes.includes(carrier.typeCode) && carrier.count > 0 && hasPermission) {
          hasAtleastOne = true
        }
      })

      return hasAtleastOne
    },
    onYearsEvent (data) {
      let that = this
      Object.keys(data).forEach(function (key, value) {
        if (that.years[key]) {
          that.years[key] = [...that.years[key], ...data[key]]
        } else {
          that.years[key] = data[key]
        }
      })

      that.$forceUpdate()
    },
    getReportTypes () {
      return this.reportTypeCodes
    },
    handleEvent (amount) {
    },
    update ($event) {
    },
    getNextLevel () {
      return this.level + 1
    },
    getReportCodesFromChildren (child) {
      let that = this

      child.children.forEach(function (_child) {
        if (
          _child.data.ReportCode &&
          !that.item.reportCodes.includes(_child.data.ReportCode)
        ) {
          that.item.reportCodes.push(_child.data.ReportCode)
        }

        that.getReportCodesFromChildren(_child)
      })
    },
    getPermissions () {
      const permissions = Array.from(this.reportCodes)
      this.getReportCodesFromChildren(this.item)
      let validPermissions = []

      if (
        parseInt(this.item.data.ItemType) === 4 ||
        (parseInt(this.item.data.ItemType) === 3 &&
          (this.item.data.ReportCode === null ||
            this.item.data.ReportCode === '' ||
            !this.item.data.ReportCode))
      ) {
        validPermissions.push('*')
      } else {
        this.item.reportCodes.map(function (_permission) {
          if (permissions.includes(_permission)) {
            validPermissions.push(_permission)
          }
        })
      }

      return validPermissions
    },
    keysForYears (years) {
      return _.sortBy(Object.keys(years)).reverse()
    },
    handleReportResponse (response, callback) {
      const sideMenuMask = document.querySelector('.sidemenu-mask')
      const ReportCode = this.item.code

      let reports = {}

      if (response.status === 200 && response.data.page.totalRecords > 0) {
        response.data.records.forEach(report => {
          // If ReportCode is Valid for the search
          if (ReportCode === report.typeCode) {
            // Check if the year is already in the list. If not, add it
            if (reports[report.yearOverride] === undefined) {
              reports[report.yearOverride] = []
            }

            // If the report yeat is already in the list
            if (reports[report.yearOverride] !== undefined) {
              // get an array of report ids from the current report year key in the list
              let ids = reports[report.yearOverride].filter((_report) => {
                return parseInt(_report.id)
              })

              // Check if the report id is present in the array
              if (!ids.includes(parseInt(report.id))) {
                reports[report.yearOverride].push({
                  ...report,
                  sidemenu: sideMenuMask
                })
              }
            }
          }
        })

        if (!this.item.isStatic) {
          this.$emit('years', {
            menuItemId: this.item.id,
            years: reports
          })
        }

        setTimeout(() => {
          this.$set(this, 'reports', reports)
          this.$forceUpdate()
        }, 100)

        this.display = true
        this.$emit('is-valid')
      } else {
        reports = {}
        this.isEmpty = true
        if (parseInt(this.item.data.ItemType) === 2) {
          this.display = false
          this.$emit('is-invalid')
        } else {
          this.display = true
          this.$emit('is-valid')
        }
      }

      // this.isLoading = false
      callback(reports)
    },
    getReports (callback) {
      if (!this.isMenuItemType('reports')) {
        callback()
        return
      }

      const ReportCode = this.item.code

      if (ReportCode) {
        if (this.item.isDocument || (this.isMenuItemType('carrier') || this.isMenuItemType('vendor') || this.item.isVendor)) {
          this.axios
            .post(`/sites/menu/types`, {
              carrierCodes: [this.item.isVendor ? 'BCC' : this.item.carrierCodes[0]],
              reportTypeCodes: [this.item.data.ReportCode]
            })
            .then((resp) => this.handleReportResponse(resp, callback))
        } else {
          this.axios
            .post('/reports/search', {
              siteId: this.activeSite.id,
              userId: this.activeUser.id,
              institutionId: this.activeUser.institutionId,
              filter: {
                typeCode: ReportCode
              }
            })
            .then((resp) => this.handleReportResponse(resp, callback))
            // .catch(error => console.debug(error)) // Comment out as this broke the pipeline.
        }
      } else {
        callback()
      }
    },
    sortByOrder (object, direction) {
      if (object !== null && object.length > 0) {
        return _.sortBy(object, 'order')
      } else {
        return []
      }
    },
    getReportsInReverse () {
      if (this.reports !== null && Object.keys(this.reports).length > 0) {
        return Object.keys(this.reports).sort().reverse()
      } else {
        return []
      }
    },
    ellipsis: (event, mouseover) => {
      event.preventDefault()
      event.stopPropagation()
      const tooltip = event.currentTarget.querySelector('.tooltip-hover')

      if (
        tooltip &&
        (event.currentTarget.classList.toString() ===
          'sidemenu-mask-dropdown' ||
          event.currentTarget.classList.toString() === 'sidemenu-mask-item')
      ) {
        if (
          event.currentTarget.children[0].scrollWidth >
            event.currentTarget.children[0].offsetWidth &&
          mouseover
        ) {
          tooltip._tooltip.show()
        } else {
          tooltip._tooltip.hide()
        }
      }
    },
    getIcon (menuItem) {
      return Constants.menu.icon[menuItem.IconType].value
    }
  }
}
</script>

<style>
.menuitem-loading {
  animation: menuitem-loading 1s infinite!important;
  opacity: .25;
}

@keyframes menuitem-loading {
  0% {
    opacity: .25;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: .25;
  }
}
</style>
