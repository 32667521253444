<template>
  <div class="dropdown" v-if="options">
    <div
      :class="{
        'not-default': !isDefault,
      }"
    >
       <!-- Dropdown Input -->
      <input class="dropdown-input"
        ref="input"
        @blur="exit"
        @focus="showOptions"
        @keyup="keyMonitor"
        v-on:keydown.enter="selectFirstResult"
        v-on:keydown.esc="exit"
        :name="name"
        v-model="searchFilter"
        :disabled="disabled"
        :placeholder="placeholder"
        :style="inputStyle" />
        <i @click="showOptions"
          :class="{
            'fa fa-chevron-down': !optionsShown
          }"
          style="
            position: absolute;
            top: .25rem;
            right: .15rem;
            cursor: pointer;
          "
        ></i>
    </div>

    <!-- Dropdown Menu -->
    <div class="dropdown-content"
      v-show="optionsShown">
      <div
        :class="{
          'dropdown-item': true,
          'active': index === 0
        }"
        @mousedown="selectOption(option)"
        v-for="(option, index) in filteredOptions"
        :key="index">
          {{ option.name || option.id || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  template: 'Dropdown',
  props: {
    isDefault: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name'
    },
    options: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
      note: 'Options of dropdown. An array of options with id and name'
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown'
    },
    maxItem: {
      type: Number,
      required: false,
      default: 6,
      note: 'Max items showing'
    },
    defaultItem: {
      type: Object,
      required: false,
      note: 'the default selected item'
    },
    inputStyle: {
      type: Object,
      required: false,
      note: 'Style of input'
    }
  },
  data () {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: ''
    }
  },
  mounted () {
    if (this.defaultItem && this.defaultItem.id != null && this.defaultItem.name) {
      this.selected = this.defaultItem
      this.searchFilter = this.defaultItem.name
    }
  },
  computed: {
    filteredOptions () {
      const filtered = []
      const regOption = new RegExp(this.searchFilter, 'ig')
      for (const option of this.options) {
        if (this.searchFilter.length < 1 || option.name.match(regOption)) {
          if (filtered.length < this.maxItem) filtered.push(option)
        }
      }

      if (this.searchFilter !== '' && !isNaN(this.searchFilter)) {
        return this.sortByInArray(filtered, 'id')
      }

      return filtered
    }
  },
  methods: {
    selectFirstResult () {
      try {
        if (this.filteredOptions[0] && this.filteredOptions[0].id) {
          this.selected = this.filteredOptions[0]
          this.optionsShown = false
          this.searchFilter = this.selected.name
          this.$emit('selected', this.selected)
        } else {
          if (this.selected.id === undefined) {
            this.selected = this.defaultItem
          }

          this.searchFilter = this.selected.name
        }

        this.optionsShown = false
      } catch {
        // Do nothing
      }
    },
    selectOption (option) {
      try {
        if (option && option.id != null) {
          this.selected = option
          this.optionsShown = false
          this.searchFilter = this.selected.name
          this.$emit('selected', this.selected)
        } else {
          if (this.selected.id === undefined) {
            this.selected = this.defaultItem
          }

          this.searchFilter = this.selected.name
        }

        this.optionsShown = false
      } catch {
        // do nothing
      }
    },
    showOptions () {
      if (!this.disabled) {
        this.searchFilter = ''
        this.optionsShown = true
      }
    },
    exit () {
      if (this.selected.id === undefined) {
        this.selected = this.defaultItem
      }

      this.searchFilter = this.selected.name

      if (Object.keys(this.selected).length > 0) {
        this.$emit('selected', this.selected)
      }

      this.optionsShown = false
    },
    // Selecting when pressing Enter
    keyMonitor: function (event) {
      if (event.key === 'Enter' && this.filteredOptions[0]) { this.selectOption(this.filteredOptions[0]) }
    }
  },
  watch: {
    searchFilter (filter) {
      if (!this.optionsShown) {
        this.$refs.input.blur()
      }

      if (this.filteredOptions.length === 0) {
        this.selected = {}
      }

      this.$emit('filter', this.searchFilter)
    }
  }
}
</script>

<style scoped>

.dropdown .dropdown-content {
  position: fixed;
  background-color: #fff;
  min-width: 248px;
  max-width: 248px;
  max-height: 248px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.5);
  overflow: auto;
  overflow-y: scroll;
  z-index: 1;
}

.dropdown {
  position: relative;
  display: block;
  margin: auto;
}

.dropdown-input {
  background: #fff;
  cursor: pointer;
  border: 1px solid #e7ecf5;
  border-radius: 3px;
  color: #333;
  display: block;
  font-size: .8em;
  padding: 6px;
  min-width: 250px;
  max-width: 250px;

  text-align: center;
}

.dropdown-input:focus {
  text-align: left;
}

.dropdown-input:hover {
  background: #f8f8fa;
}

.dropdown-input:disabled {
  background: #f8f8fa;
  color: #999;
}

.dropdown-content {
  position: absolute;
  background-color: #fff;
  min-width: 248px;
  max-width: 248px;
  max-height: 248px;
  border: 1px solid #767676;
  overflow: scroll;
  z-index: 1;
}

.dropdown-item {
  color: black;
  font-size: .7em;
  line-height: 1em;
  padding: 8px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-item.active {
  background-color: #e7ecf5;
}

.dropdown-item:hover {
  background-color: #b7d498;
}

.dropdown:hover .dropdowncontent {
  display: block;
}

.not-default, .not-default input {
  position: relative;
  width: 250px!important;
  text-align: left;
}
</style>
