<template>
  <div
    class="sidemenu-mask-dropdown-list"
    open="open"
    :level="level === 0 ? 2 : level - 1"
    style="padding-left: 2.2rem !important"
  >
    <div class="sidemenu-mask-item">
      <spin-loader/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    level: Number,
    menuItem: Object
  }
}
</script>
