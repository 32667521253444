var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:['sidemenu--default', { active: _vm.isOpen, inactive: !_vm.isOpen }],attrs:{"activeLayout":_vm.activeLayout}},[_c('div',{staticClass:"sidemenu-mobile-tab",attrs:{"isMobileDevice":_vm.isMobileDevice,"isOpen":_vm.isOpen ? 'true' : 'false'}},[_c('button',{staticClass:"nav-btn",on:{"click":function($event){$event.stopPropagation();return (function () { return (_vm.isOpen = !_vm.isOpen); }).apply(null, arguments)}}},[_c('i',{class:[
            'fa',
            'fa-bars'
          ]})])]),_c('div',{class:['sidemenu-search', { active: _vm.isOpen }]},[_c('button',{staticClass:"nav-btn",on:{"!click":function($event){$event.stopPropagation();$event.preventDefault();return (function () { return (_vm.isOpen = !_vm.isOpen); }).apply(null, arguments)}}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobileDevice),expression:"!isMobileDevice"}],class:[
            'fa',
            'fa-bars'
          ]})]),_c('div',{staticClass:"sidemenu-search-box search"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"search",attrs:{"type":"search","placeholder":"Search"},domProps:{"value":(_vm.searchQuery)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSeachQuerySubmit.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value}}})])]),_c('div',{class:['sidemenu', { active: _vm.isOpen, inactive: !_vm.isOpen }]},[(_vm.reports != null && !_vm.isLoading)?_c('div',{staticClass:"sidemenu-mask"},[_c('div',{staticClass:"sidemenu-mask-item",attrs:{"route":"Dashboard"},on:{"click":function($event){$event.stopPropagation();return _vm.route.apply(null, arguments)}}},[_vm._m(1)]),_c('div',{staticClass:"menuitem-separator-large-top"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.reports.length > 0 && _vm.reportTypeCodes && _vm.institution),expression:"reports.length > 0 && reportTypeCodes && institution"}]},_vm._l((_vm.reports),function(menuItem){return _c('div',{key:menuItem.id},[_c('menu-item',{ref:menuItem.id,refInFor:true,attrs:{"item":menuItem,"reportCodeCounts":_vm.reportCountResponse,"open":!!_vm.isOpen,"activeSite":_vm.activeSite,"carriers":_vm.institution.carriers,"activeUser":_vm.activeUser,"reportTypeCodes":_vm.reportTypeCodes,"level":0},on:{"is-invalid":function($event){_vm.documentsChildren--},"is-valid":function($event){_vm.documentsChildren++}}})],1)}),0),(false)?_c('div',{staticClass:"sidemenu-mask-dropdown",attrs:{"id":"services"},on:{"click":function () {
              _vm.isServicesOpen = !_vm.isServicesOpen;
            }}},[_c('div',[_c('i',{class:[
                'status-icon fa fa-chevron-right',
                _vm.isServicesOpen ? 'open' : '' ]}),_c('i',{staticClass:"fa fa-folder"}),_vm._v(" Service ")])]):_vm._e(),(false)?_c('div',{staticClass:"sidemenu-mask-dropdown-list",attrs:{"data-controlled-by":"services","open":_vm.isServicesOpen,"level":"1"}},[_c('div',{class:{
              'sidemenu-mask-item': true,
              active: _vm.currentRoute === 'MyServiceTeam',
            },attrs:{"route":"MyServiceTeam"},on:{"click":_vm.route}},[_vm._m(2)]),_c('div',{class:{
              'sidemenu-mask-item': true,
              active: _vm.currentRoute === 'ServiceRequest',
            },attrs:{"route":"ServiceRequest"},on:{"click":_vm.route}},[_vm._m(3)]),_c('div',{class:{
              'sidemenu-mask-item': true,
              active: _vm.currentRoute === 'BankUploads',
            },attrs:{"route":"BankUploads"},on:{"click":_vm.route}},[_c('div',[_c('i',{staticClass:"fa fa-university"}),_vm._v(" "+_vm._s(_vm.activeSite.id === 1 ? "Bank" : "Business")+" Uploads ")])])]):_vm._e(),_c('div',{staticClass:"menuitem-separator"}),_c('div',{class:{
            'sidemenu-mask-item': true,
            active: _vm.currentRoute === 'MyProfile',
          },attrs:{"route":"MyProfile"},on:{"click":_vm.route}},[_vm._m(4)]),_c('div',{class:{
            'sidemenu-mask-item': true,
            active: _vm.currentRoute === 'MyProfile',
          },on:{"click":_vm.logoutLocal}},[_vm._m(5)])]):_c('div',[_c('loading-inline',{attrs:{"color":'white'}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-search"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('i',{staticClass:"fa fa-home"}),_vm._v(" Dashboard ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('i',{staticClass:"fas fa-user-friends"}),_vm._v(" Service Team ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('i',{staticClass:"fas fa-comment-alt"}),_vm._v(" Service Request ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('i',{staticClass:"fa fa-user-circle"}),_vm._v(" Profile ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('i',{staticClass:"fa fa-sign-out-alt"}),_vm._v(" Logout ")])}]

export { render, staticRenderFns }