<template>
  <div>
    <div
      v-if="menuItemHasLoaded && !(hasNoChildren && hasNoYearsDocuments)"
      :class="['sidemenu-mask-dropdown-list']"
      :data-controlled-by="menuItem.id"
      :open="menuIsOpen"
      :level="nextLevel"
      type="folder"
    >
      <div
        v-for="childMenuItem in sortedMenuItemChildren"
        :key="childMenuItem.id"
      >
        <menu-item
          v-bind:item="childMenuItem"
          :reportCodeCounts="reportCodeCounts"
          :open="menuIsOpen"
          :activeSite="activeSite"
          :carriers="carriers"
          :activeUser="activeUser"
          :level="nextLevel"
          @isLoad="$event => menuItemIsLoading = $event"
          @years="$event => handleYearsEvent($event)"
        />
      </div>

      <div v-for="year in keysForYears(menuItemDocumentsByYears)" :key="menuItem.id + '-' + year">
          <menu-item-years
            v-bind:item="item"
            :year="year"
            :reports="menuItemDocumentsByYears[year]"
            :level="level"
            :open="menuIsOpen"
          />
        </div>
    </div>

    <div v-if="hasNoChildren && hasNoYearsDocuments">
      <div class="sidemenu-mask-dropdown-list" :level="nextLevel + 2" :open="'open'">
        <div class="sidemenu-mask-item empty">
          <i class="far fa-folder-open"></i>
          No files found
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'underscore'

export default {
  $emits: ['onYearsDocsChange', 'onNumChildrenChange'],
  components: {
    'menu-item-years': () => import('./MenuItemYears.vue'),
    'menu-item': () => import('./MenuItem.vue')
  },
  props: {
    item: Object,
    level: Number,
    reportCodeCounts: Object,
    carriers: Array,
    activeSite: Object,
    activeUser: Object,
    open: Boolean,
    years: Object,
    numChildren: Number,
    isLoad: Boolean
  },
  data () {
    return {
      menuItem: {},
      menuItemDocumentsByYears: {},
      menuItemIsLoading: false,
      menuIsOpen: false,
      menuItemChildrenCount: 0
    }
  },
  mounted () {
    this.menuIsOpen = this.open
    this.handleYearsEvent(this.year)
    this.menuItem = this.item
    this.menuItemChildrenCount = this.numChildren
  },
  watch: {
    years (value) {
      this.menuItemDocumentsByYears = value
    },
    isLoad (value) {
      this.menuItemIsLoading = value
    },
    open (value) {
      if (!value) {
        this.menuItemDocumentsByYears = []
      }

      this.menuIsOpen = value
    }
  },
  computed: {
    allReportsByYear () {
      return this.keysForYears(this.menuItemDocumentsByYears)
    },
    nextLevel () {
      return this.level + 1
    },
    hasNoYearsDocuments () {
      return (
        this.menuItemDocumentsByYears === null ||
        this.menuItemDocumentsByYears === undefined ||
        Object.keys(this.menuItemDocumentsByYears).length === 0
      )
    },
    hasNoChildren () {
      return (
        this.menuItem.children === null ||
        this.menuItem.children === undefined ||
        this.menuItem.children.length === 0
      )
    },
    reportCode () {
      return this.meunItem.data.ReportCode
    },
    menuItemHasLoaded () {
      return (
        this.menuItem !== null &&
        this.menuItem !== undefined &&
        Object.keys(this.menuItem).length > 0
      )
    },
    sortedMenuItemChildren () {
      return this.sortByOrder(this.menuItem.children, null)
    },
    menuItemDocumentsByYearsHasLoaded () {
      return (
        this.menuItemDocumentsByYears !== null &&
        this.menuItemDocumentsByYears !== undefined &&
        Object.keys(this.menuItemDocumentsByYears).length > 0
      )
    }
  },
  methods: {
    getYears (childItemId) {
      return this.menuItemDocumentsByYears[childItemId]
    },
    hasDocumentsByYears (menuItemId) {
      // let yearsForMenuItem = this.menuItemDocumentsByYears[menuItemId]

      return (
        this.menuItemDocumentsByYears !== null &&
        Object.keys(this.menuItemDocumentsByYears).length !== 0 &&
        !this.menuItem.isStatic
      )
    },
    handleYearsEvent (data) {
      if (!data) {
        return
      }

      let years = data.years
      let currentYears = this.menuItemDocumentsByYears

      Object.keys(years).forEach(year => {
        if (currentYears.hasOwnProperty(year)) {
          currentYears[year] = [...currentYears[year], ...years[year]]
        } else {
          currentYears[year] = years[year]
        }
      })

      this.menuItemDocumentsByYears = currentYears
      this.$forceUpdate()
    },
    onYearsEvent (data) {
      this.$emit('onYearsDocsChange', data)
    },
    handleValidChildCountChange (amount) {
      this.$emit('onNumChildrenChange', amount)
    },
    keysForYears (years) {
      if (!years) {
        return []
      }

      return _.sortBy(Object.keys(years)).reverse()
    },
    sortByOrder (object, direction) {
      if (object !== null && object.length > 0) {
        return _.sortBy(object, 'order')
      } else {
        return []
      }
    },
    getReport (report) {
      if (!report.hasOwnProperty('carrierCode')) {
        this.$router.push({
          name: 'ViewReport',
          params: { id: report.id, report: report }
        })
      } else if (report.hasOwnProperty('carrierCode')) {
        if (report.isCarrier || report.isVendor) {
          report.path = !report.path.includes('/clients/')
            ? report.path.replace('clients/', '')
            : report.path
        }

        this.$router.push({
          name: 'ViewDocumentScreen',
          params: { id: report.id, document: report }
        })
      } else {
        window.open(report.path)
      }
    },
    ellipsis: (event, mouseover) => {
      event.preventDefault()
      event.stopPropagation()

      const tooltip = event.currentTarget.querySelector('.tooltip-hover')

      if (
        tooltip &&
        (event.currentTarget.classList.toString() ===
          'sidemenu-mask-dropdown' ||
          event.currentTarget.classList.toString() === 'sidemenu-mask-item')
      ) {
        if (
          event.currentTarget.children[0].scrollWidth >
            event.currentTarget.children[0].offsetWidth &&
          mouseover
        ) {
          tooltip._tooltip.show()
        } else {
          tooltip._tooltip.hide()
        }
      }
    }
  }
}
</script>

<style>
.menuitem-loading {
  animation: menuitem-loading 1s infinite;
  opacity: .25;
}

@keyframes menuitem-loading {
  0% {
    opacity: .25;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: .25;
  }
}
</style>
