<template>
  <div>

    <router-link v-if="text && text != ''"
      :to="formedRoute"
      :class="linkClass"
      :style="routeStyle"
    >
      <span>
        <slot></slot>
      </span>
      <span :style="routeStyle">{{ text }}</span>
    </router-link>

    <router-link v-else
      :to="formedRoute"
      :class="linkClass"
      :style="routeStyle"
    >
      <slot>
        <span>
          <slot v-slot="icon"></slot>
        </span>
        <span>{{ text }}</span>
      </slot>
    </router-link>

  </div>
</template>

<script>
import router from '@/router'

export default {
  name: 'NavLink', // this is the name of the component that will be used in the template. e.g. '<nav-link to="/some-link">Some Link</nav-link>
  props: {
    to: {
      type: Object,
      required: false
    },
    route: {
      type: String,
      required: false
    },
    params: {
      type: Object,
      required: false
    },
    query: {
      type: Object,
      required: false
    },
    text: {
      type: String,
      required: false
    },
    linkClass: {
      type: String,
      required: false,
      default: 'has-text-white'
    }
  },
  data () {
    return {
      routeHasError: false
    }
  },
  computed: {
    routeStyle () {
      return this.routeHasError ? 'color: red!important;' : ''
    },
    formedRoute () {
      if (this.route) {
        return {
          name: this.route,
          params: this.params,
          query: this.query
        }
      }

      return this.to
    }
  },
  mounted () {
    // perform lookup for the link and provide a detailed error inside the console if the link doesn't exist.
    let route = router.options.routes.find((x) => x.name === this.formedRoute.name)

    if (!route) {
      this.routeHasError = true
      throw new Error(
        `The route ${this.formedRoute.name} does not exist. Please check your router configuration.`
      )
    }
  }
}
</script>
