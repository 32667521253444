let MockAdapter = require('axios-mock-adapter')

/**
 * @param {Axios.AxiosInstance} axios - The employees who are responsible for the project.
 */ const mockAdapter = (axios, delayResponse) => {
  let mock = new MockAdapter(axios, { delayResponse: delayResponse || 800 })
  return initialize(mock)
}

const initialize = mock => {
  mock.onGet('/service-team', { params: { institutionId: 1 } }).reply(200, {
    serviceTeam: serviceTeam
  })
  mock
    .onPost('/service-request', { serviceType: 'Billing', message: '' })
    .reply(200)
  mock.onGet('/users').reply(200, usersListBody)
  mock.onGet('/users/1').reply(200, sessionUser)
  mock.onGet('/reports').reply(200, reports)
  mock.onGet('/report-types').reply(200, reportTypes)
  mock.onGet('/sites').reply(200, sites)
  mock.onGet('/settings').reply(200, settings)
  mock.onGet('/institutions').reply(200, institutions)
  mock.onGet('/email-templates').reply(200, emailTemplates)

  mock.onGet('session/user').reply(200, sessionUser)

  mock.onAny().passThrough()
  return mock
}
export default mockAdapter
const reportTypes = {
  records: [
    {
      code: 'AQU',
      name: 'Annual Questionniare',
      description: 'sample description 1',
      order: 1
    },
    {
      code: 'SMB',
      name: 'Summary Monthly BOLI Report',
      description: 'sample description 2',
      order: 2
    },
    {
      code: 'MBR',
      name: 'Monthly BOLI Report',
      description: 'sample description 2',
      order: 3
    },
    {
      code: 'ACC',
      name: 'Liability Accrual Report',
      description: 'sample description 2',
      order: 4
    },
    {
      code: 'ABR',
      name: 'Annual BOLI Review',
      description: 'sample description 2',
      order: 5
    },
    {
      code: 'APA',
      name: 'Annual Plan Analysis',
      description: 'sample description 2',
      order: 6
    },
    {
      code: 'ITX',
      name: 'Individual Tax Report',
      description: 'sample description 2',
      order: 7
    },
    {
      code: 'BTX',
      name: 'Bank Tax Report',
      description: 'sample description 2',
      order: 8
    },
    {
      code: 'LEG',
      name: 'Legal Document',
      description: 'sample description 2',
      order: 26
    },
    {
      code: 'PRE',
      name: 'Presentation',
      description: 'sample description 2',
      order: 10
    },
    {
      code: 'DTH',
      name: 'Death Claims',
      description: 'sample description 2',
      order: 11
    },
    {
      code: 'QBR',
      name: 'Quarterly Benefit Report',
      description: 'sample description 2',
      order: 12
    }
  ],
  page: {
    page: 1,
    perPage: 1000,
    totalRecords: 13,
    sortBy: 'id',
    sortDirection: 'asc'
  }
}

const sites = {
  records: [
    {
      id: 1,
      name: 'Banking Co Site',
      displayName: 'Bank Compensation Co',
      uri: 'client.bcc-usa.com',
      theme: {
        logo: 'someurl'
      },
      createdAt: '2019-02-05T00:49:45.7333333',
      updatedAt: '2019-02-05T00:49:45.7333333'
    },
    {
      id: 2,
      name: 'Business Co Site',
      displayName: 'Business Compensation Co',
      uri: 'client.bcc-business.com',
      theme: {
        logo: 'someurl'
      },
      createdAt: '2019-02-05T00:49:45.7333333',
      updatedAt: '2019-02-05T00:49:45.7333333'
    }
  ],
  page: {
    page: 1,
    perPage: 1000,
    totalRecords: 2,
    sortBy: 'id',
    sortDirection: 'asc'
  }
}

const settings = {
  id: 1,
  minLength: 12,
  minUpperAlphaChars: 1,
  minLowerAlphaChars: 1,
  minNumericChars: 1,
  minSymbolChars: 0,
  expirationDays: 90,
  reusePasswords: 5,
  sessionTimeout: 15,
  failedAttempts: 20,
  failedDuration: 4,
  forgottenAttempts: 20,
  forgottenDuration: 4
}

const institutions = {
  records: [
    {
      id: 2038,
      name: 'NBRS Financial',
      city: 'Rising Sun',
      stateCode: 'MD',
      createdAt: '2019-02-05T00:49:45.7333333',
      updatedAt: '2019-02-05T00:49:45.7333333'
    },
    {
      id: 2033,
      name: 'First National Bank of Central Texas',
      city: 'Waco',
      stateCode: 'TX',
      createdAt: '2019-02-05T00:49:45.7333333',
      updatedAt: '2019-02-05T00:49:45.7333333'
    },
    {
      id: 2083,
      name: 'Welch State Bank',
      city: 'Welch',
      stateCode: 'OK',
      createdAt: '2019-02-05T00:49:45.7333333',
      updatedAt: '2019-02-05T00:49:45.7333333'
    },
    {
      id: 2189,
      name: 'First National Bank of Park Falls',
      city: 'Park Falls',
      stateCode: 'WI',
      createdAt: '2019-02-05T00:49:45.7333333',
      updatedAt: '2019-02-05T00:49:45.7333333'
    },
    {
      id: 2097,
      name: 'Marion County Savings Bank',
      city: 'Salem',
      stateCode: 'IL',
      createdAt: '2019-02-05T00:49:45.7333333',
      updatedAt: '2019-02-05T00:49:45.7333333'
    },
    {
      id: 2226,
      name: 'Wolf River Community Bank',
      city: 'Hortonville',
      stateCode: 'TX',
      createdAt: '2019-02-05T00:49:45.7333333',
      updatedAt: '2019-02-05T00:49:45.7333333'
    },
    {
      id: 2044,
      name: 'Lincoln Community Bank',
      city: 'Merrill',
      stateCode: 'WI',
      createdAt: '2019-02-05T00:49:45.7333333',
      updatedAt: '2019-02-05T00:49:45.7333333'
    },
    {
      id: 2099,
      name: 'Kanabec State Bank',
      city: 'Mora',
      stateCode: 'MN',
      createdAt: '2019-02-05T00:49:45.7333333',
      updatedAt: '2019-02-05T00:49:45.7333333'
    },
    {
      id: 2134,
      name: 'Sunwest Bank',
      city: 'Tustin',
      stateCode: 'CA',
      createdAt: '2019-02-05T00:49:45.7333333',
      updatedAt: '2019-02-05T00:49:45.7333333'
    },
    {
      id: 2297,
      name: 'Peoples Bank & Trust Company',
      city: 'Owenton',
      stateCode: 'KY',
      createdAt: '2019-02-05T00:49:45.7333333',
      updatedAt: '2019-02-05T00:49:45.7333333'
    }
  ],
  page: {
    page: 1,
    perPage: 1000,
    totalRecords: 10,
    sortBy: 'id',
    sortDirection: 'asc'
  }
}

const emailTemplates = {
  records: [
    {
      id: 1,
      name: 'User Signup Email',
      subject: 'Your Bank Compensation Consulting Website Log on',
      body:
        '<p>{{First-Name}} {{Last-Name}},</p><p>Welcome to the Bank Compensation Consulting website.  Below is your username and password to log on to our site and receive your reports from us online.</p><p>Please click the link below to be directed to the BCC website to log in.  After doing so, you will be asked to set your password for added security.</p><p><a href="http://www.bcc-usa.com">http://www.bcc-usa.com</a></p><p>Your login page will give you access to your monthly and annual reports.  This is also a great resource to see and contact your service team directly via the site!</p><p>Thank you for choosing Bank Compensation Consulting, and please contact us if you have any questions:</p><p>Bank Compensation Consulting</p><p>1255 West 15th Street, Suite 830</p><p>Plano, TX 75075</p><p>Phone: (972) 781-2020</p><p>Toll Free: (800) 781-2099</p><p>Fax: (972) 398-3009</p><p>clientservices@bcc-usa.com</p>',
      type: 0,
      createdAt: '2019-02-05T00:49:45.7333333',
      updatedAt: '2019-02-05T00:49:45.7333333'
    },
    {
      id: 2,
      name: 'File Upload Email',
      subject: 'Bank Compensation Consulting Has Uploaded a New Report',
      body:
        '<p>RE: {{Institution-Name}} - {{Institution Number}}</p><p>{{First-Name}} {{Last-Name}},</p><p>A new {{File-Type}} has been uploaded for your review. Please login to download the report:</p><p>{{Login-Link}}</p><p>Thank you for choosing Bank Compensation Consulting, and please contact us if you have any questions.</p><p>Best Regards,</p><p>{{Team-Member-1-Name}}</p><p>{{Team-Member-1-Title}}</p><p>Phone: {{Team-Member-1-Phone}}</p><p>Email: {{Team-Member-1-Email}}</p><p>{{Team-Member-2-Name}}</p><p>{{Team-Member-2-Title}}</p><p>Phone: {{Team-Member-2-Phone}}</p><p>Email: {{Team-Member-2-Email}}</p><p>Bank Compensation Consulting</p><p>1255 West 15th Street, Suite 830</p><p>Plano, TX 75075</p><p>Phone: (972) 781-2020</p><p>Toll Free: (800) 781-2099</p><p>Fax: (972) 398-3009</p><p>clientservices@bcc-usa.com</p>',
      type: 1,
      createdAt: '2019-02-05T00:49:45.7333333',
      updatedAt: '2019-02-05T00:49:45.7333333'
    }
  ],
  page: {
    page: 1,
    perPage: 1000,
    totalRecords: 2,
    sortBy: 'id',
    sortDirection: 'asc'
  }
}

const reports = {
  records: [
    {
      id: 1,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-17-2017-01-Bank-Asset-Report.pdf',
      yearOverride: '2017',
      name: '2017-01 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/1/content',
      createdAt: '2017-01-20T21:12:20.0',
      updatedAt: '2017-01-20T21:12:20.0'
    },
    {
      id: 2,
      typeCode: 'DTH',
      institutionId: 2033,
      uploadName: '2033-DTH-17-2016-Total-Death-Benefit-Report.pdf',
      yearOverride: '2017',
      name: '2016 Total Death Benefit Report',
      extension: 'pdf',
      path: 'api/reports/2/content',
      createdAt: '2017-01-31T20:26:41.0',
      updatedAt: '2017-01-31T20:26:41.0'
    },
    {
      id: 3,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-17-2017-02-Bank-Asset-Report.pdf',
      yearOverride: '2017',
      name: '2017-02 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/3/content',
      createdAt: '2017-02-20T21:19:58.0',
      updatedAt: '2017-02-20T21:19:58.0'
    },
    {
      id: 4,
      typeCode: 'QBR',
      institutionId: 2033,
      uploadName: '2033-QBR-17-2017-Q1-Quarterly-Benefits-Report.pdf',
      yearOverride: '2017',
      name: '2017-Q1 Quarterly Benefits Report',
      extension: 'pdf',
      path: 'api/reports/4/content',
      createdAt: '2017-02-22T22:27:05.0',
      updatedAt: '2017-02-22T22:27:05.0'
    },
    {
      id: 5,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-17-2017-03-Bank-Asset-Report.pdf',
      yearOverride: '2017',
      name: '2017-03 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/5/content',
      createdAt: '2017-03-20T20:14:57.0',
      updatedAt: '2017-03-20T20:14:57.0'
    },
    {
      id: 6,
      typeCode: 'APA',
      institutionId: 2033,
      uploadName: '2033-APA-17-2016-SCP-Plan-Analysis.pdf',
      yearOverride: '2017',
      name: '2016 SCP Plan Analysis',
      extension: 'pdf',
      path: 'api/reports/6/content',
      createdAt: '2017-03-30T15:35:57.0',
      updatedAt: '2017-03-30T15:35:57.0'
    },
    {
      id: 7,
      typeCode: 'QBR',
      institutionId: 2033,
      uploadName: '2033-QBR-17-2017-Q2-Quarterly-Benefits-Report.pdf',
      yearOverride: '2017',
      name: '2017-Q2 Quarterly Benefits Report',
      extension: 'pdf',
      path: 'api/reports/7/content',
      createdAt: '2017-04-18T22:10:08.0',
      updatedAt: '2017-04-18T22:10:08.0'
    },
    {
      id: 8,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-17-2017-04-Bank-Asset-Report.pdf',
      yearOverride: '2017',
      name: '2017-04 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/8/content',
      createdAt: '2017-04-24T13:15:36.0',
      updatedAt: '2017-04-24T13:15:36.0'
    },
    {
      id: 9,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-17-2017-05-Bank-Asset-Report.pdf',
      yearOverride: '2017',
      name: '2017-05 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/9/content',
      createdAt: '2017-05-19T14:47:03.0',
      updatedAt: '2017-05-19T14:47:03.0'
    },
    {
      id: 10,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-17-2017-06-Bank-Asset-Report.pdf',
      yearOverride: '2017',
      name: '2017-06 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/10/content',
      createdAt: '2017-06-20T20:31:23.0',
      updatedAt: '2017-06-20T20:31:23.0'
    },
    {
      id: 11,
      typeCode: 'QBR',
      institutionId: 2033,
      uploadName: '2033-QBR-17-2017-Q3-Quarterly-Benefits-Report.pdf',
      yearOverride: '2017',
      name: '2017-Q3 Quarterly Benefits Report',
      extension: 'pdf',
      path: 'api/reports/11/content',
      createdAt: '2017-07-14T15:48:00.0',
      updatedAt: '2017-07-14T15:48:00.0'
    },
    {
      id: 12,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-17-2017-07-Bank-Asset-Report.pdf',
      yearOverride: '2017',
      name: '2017-07 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/12/content',
      createdAt: '2017-07-20T20:17:38.0',
      updatedAt: '2017-07-20T20:17:38.0'
    },
    {
      id: 13,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-17-2017-08-Bank-Asset-Report.pdf',
      yearOverride: '2017',
      name: '2017-08 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/13/content',
      createdAt: '2017-08-21T20:00:30.0',
      updatedAt: '2017-08-21T20:00:30.0'
    },
    {
      id: 14,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-17-2017-09-Bank-Asset-Report.pdf',
      yearOverride: '2017',
      name: '2017-09 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/14/content',
      createdAt: '2017-09-20T14:43:47.0',
      updatedAt: '2017-09-20T14:43:47.0'
    },
    {
      id: 15,
      typeCode: 'QBR',
      institutionId: 2033,
      uploadName: '2033-QBR-17-2017-Q4-Quarterly-Benefits-Report.pdf',
      yearOverride: '2017',
      name: '2017-Q4 Quarterly Benefits Report',
      extension: 'pdf',
      path: 'api/reports/15/content',
      createdAt: '2017-10-16T19:23:21.0',
      updatedAt: '2017-10-16T19:23:21.0'
    },
    {
      id: 16,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-17-2017-10-Bank-Asset-Report.pdf',
      yearOverride: '2017',
      name: '2017-10 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/16/content',
      createdAt: '2017-10-20T14:36:35.0',
      updatedAt: '2017-10-20T14:36:35.0'
    },
    {
      id: 17,
      typeCode: 'ITX',
      institutionId: 2033,
      uploadName: '2033-ITX-17-2017-FICA-Report.pdf',
      yearOverride: '2017',
      name: '2017 FICA Report',
      extension: 'pdf',
      path: 'api/reports/17/content',
      createdAt: '2017-10-31T19:48:21.0',
      updatedAt: '2017-10-31T19:48:21.0'
    },
    {
      id: 18,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-17-2017-11-Bank-Asset-Report.pdf',
      yearOverride: '2017',
      name: '2017-11 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/18/content',
      createdAt: '2017-11-20T17:13:58.0',
      updatedAt: '2017-11-20T17:13:58.0'
    },
    {
      id: 19,
      typeCode: 'AQU',
      institutionId: 2033,
      uploadName: '2033-AQU-17-2017-Annual-Questionnaire.pdf',
      yearOverride: '2017',
      name: '2017 Annual Questionnaire',
      extension: 'pdf',
      path: 'api/reports/19/content',
      createdAt: '2017-12-01T14:50:13.0',
      updatedAt: '2017-12-01T14:50:13.0'
    },
    {
      id: 20,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-17-2017-12-Bank-Asset-Report.pdf',
      yearOverride: '2017',
      name: '2017-12 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/20/content',
      createdAt: '2017-12-20T16:06:50.0',
      updatedAt: '2017-12-20T16:06:50.0'
    },
    {
      id: 21,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-18-2018-01-Bank-Asset-Report.pdf',
      yearOverride: '2018',
      name: '2018-01 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/21/content',
      createdAt: '2018-01-19T21:27:32.0',
      updatedAt: '2018-01-19T21:27:32.0'
    },
    {
      id: 22,
      typeCode: 'DTH',
      institutionId: 2033,
      uploadName: '2033-DTH-18-2017-Total-Death-Benefit-Report.pdf',
      yearOverride: '2018',
      name: '2017 Total Death Benefit Report',
      extension: 'pdf',
      path: 'api/reports/22/content',
      createdAt: '2018-01-31T15:27:08.0',
      updatedAt: '2018-01-31T15:27:08.0'
    },
    {
      id: 23,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-18-2018-02-Bank-Asset-Report.pdf',
      yearOverride: '2018',
      name: '2018-02 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/23/content',
      createdAt: '2018-02-20T15:39:06.0',
      updatedAt: '2018-02-20T15:39:06.0'
    },
    {
      id: 24,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-18-2018-03-Bank-Asset-Report.pdf',
      yearOverride: '2018',
      name: '2018-03 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/24/content',
      createdAt: '2018-03-20T14:45:40.0',
      updatedAt: '2018-03-20T14:45:40.0'
    },
    {
      id: 25,
      typeCode: 'APA',
      institutionId: 2033,
      uploadName: '2033-APA-18-2017-SCP-Plan-Analysis.pdf',
      yearOverride: '2018',
      name: '2017 SCP Plan Analysis',
      extension: 'pdf',
      path: 'api/reports/25/content',
      createdAt: '2018-04-11T16:29:05.0',
      updatedAt: '2018-04-11T16:29:05.0'
    },
    {
      id: 26,
      typeCode: 'ACC',
      institutionId: 2033,
      uploadName: '2033-ACC-18-2018-Liability-Accrual-Report.pdf',
      yearOverride: '2018',
      name: '2018 Liability Accrual Report',
      extension: 'pdf',
      path: 'api/reports/26/content',
      createdAt: '2018-04-11T16:32:11.0',
      updatedAt: '2018-04-11T16:32:11.0'
    },
    {
      id: 27,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-18-2018-04-Bank-Asset-Report.pdf',
      yearOverride: '2018',
      name: '2018-04 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/27/content',
      createdAt: '2018-04-20T14:29:47.0',
      updatedAt: '2018-04-20T14:29:47.0'
    },
    {
      id: 28,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-18-2018-05-Bank-Asset-Report.pdf',
      yearOverride: '2018',
      name: '2018-05 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/28/content',
      createdAt: '2018-05-21T14:50:43.0',
      updatedAt: '2018-05-21T14:50:43.0'
    },
    {
      id: 29,
      typeCode: 'ABR',
      institutionId: 2033,
      uploadName: '2033-ABR-18-2018-BOLI-Review.pdf',
      yearOverride: '2018',
      name: '2018 BOLI Review',
      extension: 'pdf',
      path: 'api/reports/29/content',
      createdAt: '2018-05-31T14:45:14.0',
      updatedAt: '2018-05-31T14:45:14.0'
    },
    {
      id: 30,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-18-2018-06-Bank-Asset-Report.pdf',
      yearOverride: '2018',
      name: '2018-06 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/30/content',
      createdAt: '2018-06-20T15:04:30.0',
      updatedAt: '2018-06-20T15:04:30.0'
    },
    {
      id: 31,
      typeCode: 'MBR',
      institutionId: 2033,
      uploadName: '2033-MBR-18-2018-07-Bank-Asset-Report.pdf',
      yearOverride: '2018',
      name: '2018-07 Bank Asset Report',
      extension: 'pdf',
      path: 'api/reports/31/content',
      createdAt: '2018-07-20T15:51:01.0',
      updatedAt: '2018-07-20T15:51:01.0'
    }
  ],
  page: {
    page: 1,
    perPage: 1000,
    totalRecords: 4,
    sortBy: 'id',
    sortDirection: 'asc'
  }
}

const serviceTeam = [
  {
    id: 1,
    firstName: 'Jon',
    middleName: 'M',
    lastName: 'Anderson',
    email: 'john.anderson@bcc-usa.com',
    title: 'Senior Consultant',
    displayName: 'Jon M Anderson, CPA',
    bio:
      '<p>John M. Anderson brings more than 30 years of experience in the banking industry. He has worked as a Senior Consultant focused on the design, implementation and administration of nonqualified executive benefit plans that often included the use of BOLI financing strategies. John has also worked as a Partner at the accounting firm of Virchow, Krause and Company. Prior to his accounting career, John was the CEO of Bankers Service Corporation, a subsidiary of Bankers Bank Wisconsin, as well as a former banker. John graduated with his BA in Accounting from University of Wisconsin-Milwaukee and attained his CPA designation. He served as chairman of the Wisconsin Institute of CPAs Financial institutions committee.</p>',
    phone: '(608) 836-3080',
    extension: '',
    avatar: 'john.jpg'
  },
  {
    id: 2,
    firstName: 'Kathy',
    middleName: 'Orr',
    lastName: 'Smith',
    email: 'Kathy.Smith@bcc-usa.com',
    title: 'Principal',
    displayName: 'Kathy Orr Smith',
    bio:
      '<p>Kathy Smith, as one of the founders of Bank Compensation Consulting, brings over three decades of insurance and financial industry experience to its clients, and is highly regarded as an expert in her field. Over this time, she has developed a keen sense for how the level of service and the quality of products help banks solve the issues of retention of executive talent. Ms. Smith also works with bank accountants, attorneys, and consultants to provide a coordinated financial strategy that minimizes the impact of plan costs on earnings.</p><p>Ms. Smith frequently presents seminars on compensation strategies in the banking industry.  She has presented these seminars through let ious national and state banking trade associations.  In addition, she has taught courses on financial planning for banking professionals at the Wisconsin Graduate School of Banking. Ms. Smith graduated from Southern Methodist University in Dallas, Texas, with a bachelor\'s degree in business administration.</p>',
    phone: '(972) 781-2020 ext. 104',
    extension: '104',
    avatar: 'kathy.jpg'
  },
  {
    id: 3,
    firstName: 'Client',
    middleName: '',
    lastName: 'Services',
    email: 'clientservices@bcc-usa.com',
    title: '',
    displayName: 'Client Services',
    bio: '',
    phone: '800-781-2099',
    extension: ''
  }
]

const sessionUser = {
  id: 1,
  firstName: 'jon',
  middleName: 'mid',
  lastName: 'smith',
  title: 'dr',
  phone: '1234567890',
  extension: '1000',
  bio: 'lorem ipsum dolor',
  email: 'jon.smith@example.com',
  avatar: ''
}

const usersListBody = {
  records: [
    {
      id: 1,
      firstName: 'Charles',
      middleName: 'Martin',
      lastName: 'Jones',
      email: 'chuck.jones@acme.com',
      title: 'Dr.',
      bio: 'lorem ipsum',
      phone: '555-456-7890',
      role: 0,
      status: 0,
      createdAt: '2019-02-08T18:12:07.9866667',
      updatedAt: '2019-02-08T18:12:07.9866667'
    },
    {
      id: 3,
      firstName: 'Wile',
      middleName: 'E',
      lastName: 'Coyote',
      email: 'wile.coyote@acme.com',
      title: 'Mr.',
      bio:
        'PYXBavUPDlpsVvzUsEDgRzlNwDksQvbpdoQUQVQFEcnQPPZLvEeYJbAQnJEWKcyHJzOhefEWoxboLUyhdZwLQCgCoAhGQtucegGP',
      phone: '555-234-2345',
      role: 1,
      status: 0,
      createdAt: '2019-02-09T00:35:04.2966667',
      updatedAt: '2019-02-09T00:35:04.2966667'
    },
    {
      id: 5,
      firstName: 'Road',
      middleName: '',
      lastName: 'Runner',
      email: 'road.runner@acme.com',
      title: 'The',
      bio:
        'mfupCowmdwWqDRaYmLFywxabhDigdGNpVzXCqxfPGuXXaqEvXydwuvzpFTHzFQIWihUEuYiofqrBaDArymIfdRuZzhxXqgJplAos',
      phone: '555-123-1234',
      role: 3,
      status: 1,
      createdAt: '2019-02-09T00:45:45.1833333',
      updatedAt: '2019-02-09T00:45:45.1833333'
    }
  ],
  page: {
    page: 1,
    perPage: 1000,
    totalRecords: 3,
    sortBy: 'id',
    sortDirection: 'asc'
  }
}
