export default {
  get: {
    '/asset-reports': {
      status: 200,
      data: {
        records: [
          {
            id: 4,
            batchName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            isQuarterly: false,
            stats: { Ready: 258, remaining: 50, scheduled: 308 }
          },
          {
            id: 5,
            batchName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            isQuarterly: false,
            stats: { Ready: 258, remaining: 50, scheduled: 308 }
          },
          {
            id: 6,
            batchName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            isQuarterly: false,
            stats: { Ready: 258, remaining: 50, scheduled: 308 }
          }
        ],
        pagination: { total_records: 12, take: 5, skip: 10 }
      }
    },
    '/asset-reports/:batch_id/reports': {
      status: 200,
      data: {
        records: [
          {
            id: 631,
            reportName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            reportDisplayName: '2022-07-Bank Asset Report-1013',
            isQuarterly: false,
            createdAt: '2022-09-22T00:00:00',
            statementDate: '2022-09-22T00:00:00',
            status: 'Ready',
            completedAt: '2022-09-22T00:00:00'
          },
          {
            id: 2,
            reportName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            reportDisplayName: '2022-07-Bank Asset Report-1014',
            isQuarterly: false,
            createdAt: '2022-09-22T00:00:00',
            statementDate: '2022-09-22T00:00:00',
            status: 'Ready',
            completedAt: '2022-09-22T00:00:00'
          },
          {
            id: 3,
            reportName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            reportDisplayName: '2022-07-Bank Asset Report-1015',
            isQuarterly: false,
            createdAt: '2022-09-22T00:00:00',
            statementDate: '2022-09-22T00:00:00',
            status: 'Ready',
            completedAt: '2022-09-22T00:00:00'
          },
          {
            id: 4,
            reportName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            reportDisplayName: '2022-07-Bank Asset Report-1016',
            isQuarterly: false,
            createdAt: '2022-09-22T00:00:00',
            statementDate: '2022-09-22T00:00:00',
            status: 'Ready',
            completedAt: '2022-09-22T00:00:00'
          },
          {
            id: 5,
            reportName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            reportDisplayName: '2022-07-Bank Asset Report-1017',
            isQuarterly: false,
            createdAt: '2022-09-22T00:00:00',
            statementDate: '2022-09-22T00:00:00',
            status: 'Ready',
            completedAt: '2022-09-22T00:00:00'
          },
          {
            id: 6,
            reportName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            reportDisplayName: '2022-07-Bank Asset Report-1018',
            isQuarterly: false,
            createdAt: '2022-09-22T00:00:00',
            statementDate: '2022-09-22T00:00:00',
            status: 'Ready',
            completedAt: '2022-09-22T00:00:00'
          },
          {
            id: 7,
            reportName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            reportDisplayName: '2022-07-Bank Asset Report-1019',
            isQuarterly: false,
            createdAt: '2022-09-22T00:00:00',
            statementDate: '2022-09-22T00:00:00',
            status: 'Ready',
            completedAt: '2022-09-22T00:00:00'
          },
          {
            id: 8,
            reportName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            reportDisplayName: '2022-07-Bank Asset Report-1020',
            isQuarterly: false,
            createdAt: '2022-09-22T00:00:00',
            statementDate: '2022-09-22T00:00:00',
            status: 'Ready',
            completedAt: '2022-09-22T00:00:00'
          },
          {
            id: 9,
            reportName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            reportDisplayName: '2022-07-Bank Asset Report-1021',
            isQuarterly: false,
            createdAt: '2022-09-22T00:00:00',
            statementDate: '2022-09-22T00:00:00',
            status: 'Ready',
            completedAt: '2022-09-22T00:00:00'
          },
          {
            id: 10,
            reportName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            reportDisplayName: '2022-07-Bank Asset Report-1023',
            isQuarterly: false,
            createdAt: '2022-09-22T00:00:00',
            statementDate: '2022-09-22T00:00:00',
            status: 'Refreshing',
            completedAt: '2022-09-22T00:00:00'
          },
          {
            id: 11,
            reportName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            reportDisplayName: '2022-07-Bank Asset Report-1024',
            isQuarterly: false,
            createdAt: '2022-09-22T00:00:00',
            statementDate: '2022-09-22T00:00:00',
            status: 'Refreshing',
            completedAt: '2022-09-22T00:00:00'
          },
          {
            id: 12,
            reportName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            reportDisplayName: '2022-07-Bank Asset Report-1025',
            isQuarterly: false,
            createdAt: '2022-09-22T00:00:00',
            statementDate: '2022-09-22T00:00:00',
            status: 'Ready',
            completedAt: '2022-09-22T00:00:00'
          },
          {
            id: 13,
            reportName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            reportDisplayName: '2022-07-Bank Asset Report-1013',
            isQuarterly: false,
            createdAt: '2022-09-22T00:00:00',
            statementDate: '2022-09-22T00:00:00',
            status: 'Refreshing',
            completedAt: '2022-09-22T00:00:00'
          },
          {
            id: 14,
            reportName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            reportDisplayName: '2022-07-Bank Asset Report-1013',
            isQuarterly: false,
            createdAt: '2022-09-22T00:00:00',
            statementDate: '2022-09-22T00:00:00',
            status: 'Ready',
            completedAt: '2022-09-22T00:00:00'
          },
          {
            id: 15,
            reportName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            reportDisplayName: '2022-07-Bank Asset Report-1013',
            isQuarterly: false,
            createdAt: '2022-09-22T00:00:00',
            statementDate: '2022-09-22T00:00:00',
            status: 'Generating',
            completedAt: '2022-09-22T00:00:00'
          },
          {
            id: 16,
            reportName: 'Bank Asset Reports',
            year: 2022,
            month: 8,
            reportDisplayName: '2022-07-Bank Asset Report-1013',
            isQuarterly: false,
            createdAt: '2022-09-22T00:00:00',
            statementDate: '2022-09-22T00:00:00',
            status: 'Ready',
            completedAt: '2022-09-22T00:00:00'
          }
        ],
        pagination: {
          total_records: 16,
          take: 16,
          skip: 0
        }
      }
    },
    '/asset-reports/:batch_id/reports/:report_id': {
      status: 200,
      data: {
        report: {
          id: 4,
          reportName: 'Bank Asset Reports',
          year: 2022,
          month: 8,
          reportDisplayName: '2022-07-Bank Asset Report-1013',
          isQuarterly: false,
          createdAt: '2022-09-22T00:00:00',
          completedAt: '2022-09-22T00:00:00'
        }
      }
    },
    '/asset-reports/institutions': {
      status: 200,
      data: {
        'institutions': [
          {
            'id': 1001,
            'subsidary_id': '',
            'name': 'Sample Bank'
          },
          {
            'id': 1002,
            'subsidary_id': 'a',
            'name': 'Limestone Bank'
          },
          {
            'id': 1002,
            'subsidary_id': 'b',
            'name': 'Limestone Bank'
          }
        ]
      }
    },
    '/asset-reports/:batch_id/download': 'https://www.africau.edu/images/default/sample.pdf'
  },
  post: {
    '/asset-reports': {
      status: 200,
      data: {
        message: 'Asset report batch created successfully'
      }
    }
  },
  delete: {
    '/asset-reports/:batch_id': {
      status: 200,
      data: {
        message: 'Asset report batch deleted successfully'
      }
    },
    '/asset-reports/:batch_id/reports/:report_id': {
      status: 200,
      data: {
        message: 'Asset report deleted successfully'
      }
    }
  }
}
