<template>

<div :class="{
  'header--container': true,
  'is-open': isOpen,
  'is-closed': !isOpen,
}">

  <div v-if="activeLayout === 'osr-facing' || activeLayout === 'client-facing'" style="max-width: 100vw; overflow:hidden;">
    <div v-if="activeLayout === 'osr-facing'">
      <div id="institution-switcher" v-if="activeLayout === 'osr-facing'" class="osr-facing">
        <span id="switch-institutions">Switch institutions: </span>
        <Dropdown
          v-if="options.length > 0 && activeInstitution"
          @selected="(option) => setActiveInstitutionId(option.id)"
          @filter="() => null"
          :options="options"
          :maxItem="1000"
          :defaultItem="activeInstitution"
        />
        </div>
    </div>
    <div id="header-area" class="osr client has-background-white">
      <div id="header-left">
        Welcome, <b style="margin-left: .5rem;"><i>{{ user.firstName }} {{ user.lastName }}</i></b>
      </div>
      <div id="header-center" style="">
        <span><b>{{ getInstitutionsName() }} {{ activeSite.id === 1 ? 'BOLI Portal' : 'COLI Portal' }}</b></span>
      </div>
      <div id="header-right">
        <img style="max-height: 75px; max-width: 280px; width: auto; margin-top: -12px;" :src="logo"/>
      </div>
    </div>
  </div>

  <div v-if="activeLayout === 'administrative'">
    <div id="header-area" class="has-background-white">
      <div id="header-left" class="flex">
        <h2 style="font-size: 1.2rem; font-weight: 600;">Welcome, {{user.firstName}} {{user.lastName}}</h2>
      </div>

      <div id="header-center" style="text-align:center;">
        <div
          v-if="sites && sites.length > 0"
          id="institution-switcher"
          class="admin-facing"
        >
          <div id="switch-institutions">Active Site:</div>
            <Dropdown
              v-if="sites.length > 0 && activeSite.id"
              @selected="(option) => setActiveSiteId(option.id)"
              @filter="() => null"
              :options="sites"
              :maxItem="1000"
              :defaultItem="{ id: activeSite.id, name: activeSite.name }"
            />
        </div>
      </div>

      <div id="header-right">
        <div id="header-logo" class="flex">
          <img :src="activeSite.logoUrl" style="max-height: 70px;" />
        </div>
      </div>

    </div>
  </div>

  <sidebar-menu v-if="!isFullScreen && (activeLayout === 'osr-facing' || activeLayout === 'client-facing')" :activeLayout="activeLayout" :activeSite="activeSite" :activeUser="user" @openChanged="$event => isOpen = $event"/>
</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Dropdown from './partials/dropdowns/SearchableDropdown.vue'
import { updateSession, logout, activeSite } from '../vuex-actions'
import SidebarMenu from './SidebarMenu.vue'
import { bus } from '../main'

export default {
  components: { SidebarMenu, Dropdown },
  name: 'siteheader',
  props: {
    activeLayout: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      institutions: [],
      activeInstitution: null,
      isFullScreen: false, // used to override the sidebar menu
      options: [],
      sites: [],
      user: { id: 0, firstName: '', lastName: '', institution: { name: '', logo: '' } },
      logo: null,
      isOpen: true
    }
  },
  async mounted () {
    this.$nextTick(() => {
      const appBody = document.getElementById('app-body-area')

      if (appBody) { appBody.setAttribute('activeLayout', this.activeLayout) } else { document.getElementById('app-body-area-cp').setAttribute('activeLayout', this.activeLayout) }
    })

    if (!['Staff', 'Manager'].includes(this.clientSession.userRole)) {
      const logo = await this.getInstitutionsLogo().then(logo => { return logo })

      if (logo) {
        this.logo = logo
      }
    }

    if (!this.logo || this.logo === null || this.logo === undefined) {
      this.logo = this.activeSite.logoUrl
    }

    bus.$on('updated-user', (_user) => {
      this.user = {
        id: _user.id,
        firstName: _user.firstName,
        lastName: _user.lastName,
        institution: this.user.institution
      }
    })

    bus.$on('full-screen-toggle', isFullScreen => {
      this.isFullScreen = isFullScreen
    })
  },
  computed: {
    ...mapState(['clientSession', activeSite]),
    activeInstitutionId: {
      get () {
        return this.clientSession.institutionId
      },
      async set (value) {
        await this.setActiveInstitutionId(value)
        this.getOptions()
      }
    },
    activeSiteId: {
      get () {
        return this.clientSession.siteId
      },
      async set (value) {
        await this.setActiveSiteId(value)
      }
    }
  },
  async created () {
    this.getUserInfo()

    this.getInstitutionsLookup()

    if (this.activeLayout === 'administrative') {
      this.getSiteLookup()
    }
  },
  watch: {
    activeInstitutionId (id) {
      this.getOptions()
    }
  },
  methods: {
    alert (object) {
      // alert(object)
    },
    getOptions () {
      const institutions = this.sortByInArray(this.institutions, 'name')
      let options = []
      for (let i = 0; i < institutions.length; i++) {
        const institution = institutions[i]

        if (this.activeInstitutionId === institution.id) {
          this.activeInstitution = {
            id: institution.id,
            name: `${institution.name} - ${institution.id}`
          }
        }

        options.push({
          id: institution.id,
          name: `${institution.name} - ${institution.id}`
        })
      }

      return options
    },
    removeNumberFromName (name) {
      const numbers = name.substring(0, 4)

      if (!isNaN(parseInt(numbers)) && !isNaN(numbers)) {
        return name.substring(4, name.length)
      }

      return name
    },
    ...mapMutations([updateSession, logout]),
    async getUserInfo () {
      // TODO: move to vuex and only load on session update
      try {
        let route = `/users/${this.clientSession.userId}`
        this.isLoading = true
        let response = await this.axios.get(route)
        this.user = response.data
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    getInstitutionsName () {
      let name = ''
      if (this.user.institutionName) {
        name = this.removeNumberFromName(this.user.institutionName)
      }

      this.institutions.map((institution) => {
        if (parseInt(this.activeInstitutionId) === parseInt(institution.id)) {
          name = institution.name
        }
      })

      return name
    },
    // getInstitutionsLogo () {
    //   let logo = ''
    //   if (this.user.institutionLogo !== null) {
    //     logo = this.user.institutionLogo
    //   }

    //   if (logo === '' || logo === null) {
    //     // It did not come on the user so we need to search for it.
    //     this.institutions.map((institution) => {
    //       if (parseInt(this.activeInstitutionId) === parseInt(institution.id)) {
    //         logo = institution.logo
    //       }
    //     })
    //   }

    //   // Shows by default
    //   if (logo === '' || logo === null) {
    //     logo = this.activeSite.logoUrl
    //   }

    //   return logo
    // },
    async getInstitutionsLogo () {
      // TODO: dedupe
      try {
        let route = `/institutions/${this.activeInstitutionId}`
        this.isLoading = true
        let response = await this.axios.get(route)

        if (response.status === 200) {
          return response.data.logo
        }
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getInstitutionsLookup () {
      // TODO: dedupe + vuex?
      try {
        let route = `/institutions`
        this.isLoading = true
        let response = await this.axios.get(route)
        // this.institutions = new Map(
        //   response.data.records.map(i => [i.id, i.name])
        // )
        this.institutions = response.data.records

        this.options = this.getOptions()
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getSiteLookup () {
      // TODO: dedupe + vuex?
      try {
        let route = `/sites`
        this.isLoading = true
        let response = await this.axios.get(route)
        this.sites = []

        response.data.records.map(site => {
          this.sites.push({
            id: site.id,
            name: site.name,
            url: site.url
          })
        })

        // console.log(this.sites)
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async logoutlocal () {
      this.$router.push({ name: 'Logout' })
    },
    async setActiveInstitutionId (id) {
      if (this.activeInstitutionId !== id) {
        try {
          let route = `/session/institution/${id}`
          let response = await this.axios.post(route)
          await this[updateSession](response.data)
          window.location.href = '/'
        } catch (error) {
          this.handleApiErr(error)
        } finally {
          this.isLoading = false
        }
      }
    },
    async setActiveSiteId (id) {
      let site = this.sites.find(x => x.id === parseInt(id))
      let url = site.url
      if (url && !url.startsWith('http')) {
        url = `https://${url}`
      }
      window.location.href = url
    }
  }
}
</script>

<style>
.k-calendar-view {
  width: 300px!important;
}

.k-grid-header .k-header > .k-link > .k-icon.k-i-sort-desc-sm, .k-grid-header .k-header > .k-link > .k-icon.k-i-sort-asc-sm {
    vertical-align: text-top;
    margin-right: 7px;
    margin-left: 0px!important;
    float: left;
}

.annotationLayer, .annotationLayer section {
    position: absolute;
    display: none !important;
}

#header-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  z-index: 30;
}

.annotationLayer, .annotationLayer section {
    position: absolute;
    display: none !important;
}

.annotationLayer, .annotationLayer section {
    position: absolute;
    display: none !important;
}

.annotationLayer, .annotationLayer section {
    position: absolute;
    display: none !important;
}

#app-body-area[activelayout='osr-facing'] {
    width: 80%;
    right: 0!important;
    background-color: #21335a;
}

#app-body-area[activelayout="administrative"] {
  top: 99px;
  width: 100%!important;
  left: 0!important;
}

#header-area.client {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgba(0,0,0,0.05);
}

#header-area.osr {
  position: relative!important;
}

#header-area.client div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
}

#header-area.client #header-left {
  justify-content: flex-start;
  padding: 10px 25px;
}

#header-area.client #header-right {
  justify-content: flex-end;
  padding: 10px 20px;
}

#header-left {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30%;
  height: 70px;
}
#header-right {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 70px;
  width: 30%;
}

#header-right > img {
  max-height: 70px;
}

.header--container {
  background-color: #d9d9d9;
}

#header-center {
  top: 10px;
  height: 70px;
  width: 40%;
  margin: auto;
}
#header-logo {
  height: 70px;
  background-repeat: no-repeat;
  background-position: top 0px left 0px;
}

#institution-selector {
  padding: 5px;
  background-color: white;
  border-radius: 5px;
  border:1px solid #7fb942;
  text-align: center;
}

#institution-selector > option {
  text-align: left!important;
  text-align-last:left!important;
}

#institution-switcher.osr-facing {
  position: relative;
  background-color: #7fb942;
  padding: 10px;
  border-bottom: 2px solid rgba(0,0,0,0.1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  z-index: 35;
}

#institution-switcher.admin-facing {
  position: relative;
  padding-top: 10px;

  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  z-index: 35;
}

#institution-switcher.admin-facing input ~ i {
  position: absolute;
  right: 8px!important;
  top:8px!important;
}

#institution-switcher.admin-facing input {
  background-color: #f5f5f5;
}

#switch-institutions {
  position: absolute;
  left: 20px;
  float: left;
  color: white;
}

@media (max-width: 800px) {
  #institution-switcher.osr-facing {
    display: flex;
    flex-direction: row;
  }

  #institution-selector {
    width: 100%;
  }

  #switch-institutions {
    position: relative;
    width: 100%;
  }

  #header-right {
    display: none!important;
  }

  #header-right > img {
    display: inherit;
  }

  #header-center {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start!important;
    align-content: center;
  }

  #header-left {
    padding: 0!important;
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto 0!important;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start!important;
    left: 0px!important;
  }

  #header-area.client {
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    align-items: center;

    padding: 20px;
    padding-bottom: 30px;
  }

  #header-area {
    padding: 0px;
    margin: 0px;
    position: inherit;
  }
}
#header-right {
  height: 100%;
}

#header-right > div.flex {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

}

#header-left {
  height: 100%;
}

#header-left.flex {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
</style>
