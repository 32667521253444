<template>
  <div>
    <div
      :class="['sidemenu--default', { active: isOpen, inactive: !isOpen }]"
      :activeLayout="activeLayout"
    >
      <div class="sidemenu-mobile-tab" :isMobileDevice="isMobileDevice" :isOpen="isOpen ? 'true' : 'false'">
        <button class="nav-btn" @click.stop="() => (isOpen = !isOpen)">
          <i
            :class="[
              'fa',
              'fa-bars'
            ]"
          ></i>
        </button>
      </div>
      <div
        :class="['sidemenu-search', { active: isOpen }]"
      >
        <button class="nav-btn" @click.stop.prevent.capture="() => (isOpen = !isOpen)">
          <i
            v-show="!isMobileDevice"
            :class="[
              'fa',
              'fa-bars'
            ]"
          ></i>
        </button>
        <div class="sidemenu-search-box search">
          <div class="icon">
            <i class="fa fa-search"></i>
          </div>
          <input
            class="search"
            type="search"
            placeholder="Search"
            v-model="searchQuery"
            @keyup.enter="handleSeachQuerySubmit"
          />
        </div>
      </div>
      <div
        :class="['sidemenu', { active: isOpen, inactive: !isOpen }]"
      >
        <div class="sidemenu-mask" v-if="reports != null && !isLoading">
          <div class="sidemenu-mask-item" route="Dashboard" @click.stop="route">
            <div>
              <i class="fa fa-home"></i>
              Dashboard
            </div>
          </div>
          <div class="menuitem-separator-large-top"></div>
          <div v-show="reports.length > 0 && reportTypeCodes && institution">
            <div v-for="menuItem in reports" :key="menuItem.id">
              <menu-item
                v-bind:item="menuItem"
                :reportCodeCounts="reportCountResponse"
                :open="!!isOpen"
                :activeSite="activeSite"
                :carriers="institution.carriers"
                :activeUser="activeUser"
                :reportTypeCodes="reportTypeCodes"
                :level="0"
                :ref="menuItem.id"
                @is-invalid="documentsChildren--"
                @is-valid="documentsChildren++"
              />
            </div>
          </div>

          <div
            v-if="false"
            class="sidemenu-mask-dropdown"
            id="services"
            @click="
              () => {
                isServicesOpen = !isServicesOpen;
              }
            "
          >
            <div>
              <i
                :class="[
                  'status-icon fa fa-chevron-right',
                  isServicesOpen ? 'open' : '',
                ]"
              ></i>
              <i class="fa fa-folder"></i>
              Service
            </div>
          </div>

          <div
            v-if="false"
            class="sidemenu-mask-dropdown-list"
            data-controlled-by="services"
            :open="isServicesOpen"
            level="1"
          >
            <div
              :class="{
                'sidemenu-mask-item': true,
                active: currentRoute === 'MyServiceTeam',
              }"
              route="MyServiceTeam"
              @click="route"
            >
              <div>
                <i class="fas fa-user-friends"></i>
                Service Team
              </div>
            </div>
            <div
              :class="{
                'sidemenu-mask-item': true,
                active: currentRoute === 'ServiceRequest',
              }"
              route="ServiceRequest"
              @click="route"
            >
              <div>
                <i class="fas fa-comment-alt"></i>
                Service Request
              </div>
            </div>
            <div
              :class="{
                'sidemenu-mask-item': true,
                active: currentRoute === 'BankUploads',
              }"
              route="BankUploads"
              @click="route"
            >
              <div>
                <i class="fa fa-university"></i>
                {{ activeSite.id === 1 ? "Bank" : "Business" }} Uploads
              </div>
            </div>
          </div>
          <div class="menuitem-separator"></div>
          <div
            :class="{
              'sidemenu-mask-item': true,
              active: currentRoute === 'MyProfile',
            }"
            route="MyProfile"
            @click="route"
          >
            <div>
              <i class="fa fa-user-circle"></i>
              Profile
            </div>
          </div>

          <div
            :class="{
              'sidemenu-mask-item': true,
              active: currentRoute === 'MyProfile',
            }"
            @click="logoutLocal"
          >
            <div>
              <i class="fa fa-sign-out-alt"></i>
              Logout
            </div>
          </div>
        </div>
        <div v-else>
          <loading-inline :color="'white'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable brace-style
import Constants from '../constants'
import { mapState } from 'vuex'
import MenuItem from './MenuItem'

// eslint-disable-next-line no-unused-vars
class CodesObject {
  constructor (code, type) {
    this.code = code
    this.type = type
  }
}

export default {
  name: 'SidebarMenu',
  props: {
    activeLayout: {
      type: String,
      required: true
    },
    activeSite: {
      type: Object,
      required: true
    },
    activeUser: {
      type: Object,
      required: true
    }
  },
  components: {
    MenuItem,
    'loading-inline': () => import('./LoadingInline')
  },
  data () {
    return {
      open: window.innerWidth > 700,
      menuStructure: Array.from(JSON.parse(this.activeSite.siteNavigation)),
      currentRoute: this.$route.name ? this.$route.name : '#',
      Constants: Constants,
      reports: [],
      isOpen: true,
      institution: {},
      isDocumentsOpen: false,
      documentsChildren: 0,
      isServicesOpen: false,
      reportTypeCodes: null,
      searchQuery: '',
      carriers: [],
      reportCountResponse: {},
      isLoading: true
    }
  },
  mounted () {
    this.getReportTypes()
    this.getSiteNavigation()
    this.getInstitutions()

    if (this.isMobileDevice) {
      this.isOpen = false
    } else {
      let sideBarPref = window.localStorage.getItem('sidebarOpen')
      this.isOpen = sideBarPref ? sideBarPref === 'true' : true
    }
  },
  watch: {
    isOpen: function (val) {
      this.$emit('openChanged', val)
      window.localStorage.setItem('sidebarOpen', val ? 'true' : 'false')
    }
  },
  computed: {
    ...mapState(['clientSession']),
    isMobileDevice () {
      return window.innerWidth < 800
    }
  },
  methods: {
    async getInstitutions () {
      let that = this
      try {
        this.axios
          .get(`/institutions/${this.clientSession.institutionId}`)
          .then(function (response) {
            if (response.status === 200) {
              that.institution = response.data
            }
          })
      } catch (error) {
        this.handleApiErr(error)
      }
    },
    async getSiteNavigation () {
      let that = this
      this.axios.get('sites/' + this.activeSite.id).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
          that.menuStructure = JSON.parse(response.data.siteNavigation)
        }

        that.axios.get('/sites/menu/counts').then(function (resp) {
          if (resp.status >= 200 && resp.status < 300) {
            that.reportCountResponse = { // TODO: remove this when the UI is fixed
              ...resp.data
            }
            that.getReports()
          }
        })
      })
    },
    handleSeachQuerySubmit () {
      this.isOpen = true
      if (this.$route.name === 'Search') {
        this.$router.replace({ query: { query: this.searchQuery } })
      } else {
        this.$router.push({
          name: 'Search',
          params: { query: this.searchQuery }
        })
      }
    },
    async getReportTypes () {
      const that = this
      this.axios
        .get(`/memberships/${this.clientSession.userId}`)
        .then(function (response) {
          if (response.status === 200) {
            that.reportTypeCodes = response.data.reportTypeCodes
          }
        })
    },
    removeChild () {
      if (this.documentsChildren > 1) {
        this.documentsChildren--
      }
    },
    addChild () {
      this.documentsChildren++
    },
    getReports () {
      let structure = []
      // const startTime = new Date().getTime()

      const isItemType = (item, type) => {
        return Constants.menu.itemTypes[type] === item.ItemType
      }

      // eslint-disable-next-line no-unused-vars
      const findReportOrDocumentCodes = (item) => {
        let codes = {
          vendor: [],
          carrier: [],
          document: [],
          reports: []
        }

        if (!item.code && !item.isLink) {
          return codes
        }

        if (isItemType(item, 'vendor')) {
          codes.vendor.push(item.code)
        }

        if (isItemType(item, 'carrier')) {
          codes.carrier.push(item.code)
        }

        if (isItemType(item, 'document')) {
          codes.reports.push(item.ReportCode)
        }

        if (isItemType(item, 'report')) {
          codes.reports.push(item.code)
        }

        if (item.isLink && item.data.Route === 'BankUploads') {
          codes.reports.push('USR')
          item.code = 'USR'
        }
        return codes
      }

      /**
       * Bubble up report codes to parents. This should work like this:
       *
       * A menuItem is supplied with a report code. This report code is bubbled up to the parent.
       * If the parent already has a report code, it is not overwritten.
       * If the parent has a report code, but the report code is not the same as the one bubbled up, it is added to the parent.
       * If the parent has a report code, and the report code is the same as the one bubbled up, it is not added to the parent.
       *
       * @param {MenuItem} item
       * @param {CodesObject[]} codesObject
       */
      // eslint-disable-next-line no-unused-vars
      const bubbleReportOrDocumentCodesUpToParents = (
        menuItem,
        codesObjects
      ) => {
        menuItem.vendorCodes = [
          ...menuItem.vendorCodes,
          ...codesObjects.vendor.filter((code) => code !== undefined)
        ]
        menuItem.carrierCodes = [
          ...menuItem.carrierCodes,
          ...codesObjects.carrier.filter((code) => code !== undefined)
        ]
        menuItem.reportCodes = [
          ...menuItem.reportCodes,
          ...codesObjects.reports.filter((code) => code !== undefined)
        ]
        // menuItem.documentCodes = codesObjects.document

        if (menuItem.parent && menuItem.parent.id) {
          bubbleReportOrDocumentCodesUpToParents(menuItem.parent, codesObjects)
        }
      }

      // Remove the process item from the queued items.
      const removeFromMenuStructure = (item) => {
        this.menuStructure = this.menuStructure.filter(
          (menuItem) => menuItem.Id !== item.Id
        )
      }

      // Search the object for the parent of the menuItem. Please note that this is NOT efficient.
      const findParentInStructure = (item, structure) => {
        if (structure.length === 0) {
          return undefined
        }

        let possibleParent = null

        for (const menuItem of structure) {
          if (parseInt(menuItem.id) === parseInt(item.ParentId)) {
            possibleParent = menuItem
          } else if (menuItem.children.length > 0) {
            const check = findParentInStructure(item, menuItem.children)
            // eslint-disable-next-line no-unneeded-ternary
            possibleParent = check ? check : possibleParent
          }
        }

        return possibleParent
      }

      // Go through each menu item and build the structure
      const buildStructure = (level) => {
        let menuItemStruct = {}

        this.menuStructure.forEach((menuItem) => {
          menuItemStruct = {
            id: menuItem.Id,
            ParentId: menuItem.ParentId,
            parent: {},

            isVendor: isItemType(menuItem, 'vendor'),
            isCarrier: isItemType(menuItem, 'carrier'),
            isLink: isItemType(menuItem, 'link'),
            linkRequiresPermission: isItemType(menuItem, 'link') && menuItem.Route === 'BankUploads',

            vendorCodes: [],
            carrierCodes: [],
            reportCodes: [],

            code: menuItem.ReportCode,

            children: [],
            isStatic: menuItem.IsPersistent,

            level: level,

            isDocument:
              isItemType(menuItem, 'vendor') || isItemType(menuItem, 'carrier'),

            reports: {}, // This is where we will attach reports once loaded
            years: {}, // This is where we will attach reports by year once loaded

            order: menuItem.Order,
            data: menuItem
          }

          if (menuItem.ParentId === 0) {
            // Create first entry into the structure
            structure.push(menuItemStruct)
            removeFromMenuStructure(menuItem)
            return // We completed the task for the top level item.
          }

          // Not a top level item, so we need to find the parent.
          let parent = findParentInStructure(menuItem, structure) // Undefined or the parent ;; Starts at the top level of the structure during each pass

          if (parent && menuItem.ParentId) {
            menuItemStruct.parent = parent
            menuItemStruct.isVendor = parent.isVendor
            menuItemStruct.isCarrier = parent.isCarrier

            parent.children.push(menuItemStruct)
            removeFromMenuStructure(menuItem)
          }
        })
      }

      let level = 0
      const maxLevels = 5 // Extending this means extending how the UI can render this.
      while (this.menuStructure.length > 0 && level < maxLevels) {
        buildStructure(level)
        level++
      }

      /**
       * Start at the top level of the structure and return each menuItem.
       *
       * NOTE: // When this is rendered it will make a call to the reportCodesObject to see if the reportCode it contains
       * is in the reportCodesObject. If it is, it will add the report to the menuItem. If not, we will not render the menuItem.
       */

      const doReportCodeFindAndBubble = (menuItem) => {
        if (!menuItem) return

        const menuItemCodes = findReportOrDocumentCodes(menuItem)

        if (
          menuItemCodes &&
          ((menuItemCodes.carrier && menuItemCodes.carrier.length > 0) ||
            (menuItemCodes.vendor && menuItemCodes.vendor.length > 0) ||
            (menuItemCodes.reports && menuItemCodes.reports.length > 0))
        ) {
          bubbleReportOrDocumentCodesUpToParents(menuItem, menuItemCodes)
        }

        if (menuItem.children.length > 0) {
          menuItem.children.forEach((childMenuItem) => {
            doReportCodeFindAndBubble(childMenuItem)
          })
        }
      }

      // Start at the top level of the structure and mutate each of the menuItems children.
      structure.forEach((menuItem) => {
        menuItem.children.forEach((childMenuItem) => {
          doReportCodeFindAndBubble(childMenuItem)
        })
      })

      const sortedReports = structure.sort(
        (a, b) => parseInt(a.data.Order) - parseInt(b.data.Order)
      )
      // const endTime = new Date().getTime()
      // console.log('Time to build menu structure: ' + (endTime - startTime) + 'ms')
      this.$set(this, 'reports', sortedReports)
      this.$set(this, 'isLoading', false)
    },
    async logoutLocal () {
      this.$router.push({ name: 'Logout' })
    },
    getCount (code) {
      let codes = this.reportCountResponse.reports.filter(function (
        _reportType
      ) {
        if (_reportType.typeCode === code && _reportType.count > 0) {
          return _reportType
        }
      })

      if (codes.length > 0) {
        return [codes[0].count]
      } else {
        if (codes.length < 1) {
          codes = this.reportCountResponse.carriers.filter(function (
            _reportType
          ) {
            if (_reportType.typeCode === code && _reportType.count > 0) {
              return _reportType
            }
          })

          if (codes.length > 0) {
            return [codes[0].count]
          } else {
            codes = this.reportCountResponse.vendors.filter(function (
              _reportType
            ) {
              if (_reportType.typeCode === code && _reportType.count > 0) {
                return _reportType
              }
            })

            if (codes.length > 0) {
              return [codes[0].count]
            }
          }
        }
      }

      return 0
    },
    route (event) {
      event.preventDefault()
      event.stopPropagation()

      this.currentRoute = event.currentTarget.attributes.route.value
      this.$router.push({ name: event.currentTarget.attributes.route.value })
      // this.isOpen = false
    }
  }
}
</script>

<style scoped>
i {
  transition: all 0.2s linear;
}
#body-content-area-all {
  width: 100% !important;
  padding: 0rem;
  overflow-y: hidden;
}
</style>

<style>
.menuitem-separator {
  margin: 0.5rem;
  border: 1px dashed #70798c;
  border-bottom: 0px;
}

.menuitem-separator-large-top {
  margin: 0.5rem;
  margin-top: 1rem;
  border: 1px dashed #70798c;
  border-bottom: 0px;
}

@media (max-width: 800px) {
  .sidemenu-search {
    /* width: 100%;
    margin-left: -350px;
    margin-right: -350px;
    position: absolute;
    top: 0; left: 0;
    z-index: 100;
    transition: all .5s ease-in-out; */
  }

  .sidemenu-search.active {
    /* position: absolute;
    top: 0; left: 0;
    width: 100%!important;
    margin: 0%!important;
    justify-content: start;
    padding-left: 1.3rem;
    transition: all .5s ease-in-out;
    z-index: 30; */
  }

  .sidemenu-search.active .sidemenu-search-box {
    /* width: 82%; */
  }
}
@media (max-width: 800px) {
  .sidemenu-search {
    position: absolute;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100px;

    justify-content: flex-start;
    padding-left: 1.3rem;
  }

  .sidemenu-search-box {
    width: calc(100% - 45px - 1.5rem);
  }

  .sidemenu--default {
    position: absolute !important;
    width: 100% !important;
    height: 100vh !important;
    top: 0 !important;
    left: 0 !important;

    background-color: #21335a !important;
  }

  .sidemenu--default.inactive {
    margin-left: -100vw;
    animation: slideout 0.5s ease-in-out;
  }

  .sidemenu--default.active {
    margin: 0px;
    /* animation: slidein 0.5s ease-in-out; */
  }

  .sidemenu--default .sidemenu {
    position: relative;
    width: 100% !important;
    height: 100% !important;
    margin: 0px !important;
    z-index: 1000 !important;
    display: flex !important;
  }

  #header-center {
    font-size: 18px !important;
  }

  #app-body-area[activelayout="client-facing"] {
    width: 100% !important;
  }
  #app-body-area[activelayout="osr-facing"] {
    width: 100% !important;
  }

  .sidemenu-mobile-tab {
    display: inline-block !important;
    position: absolute;
    width: 50px;
    top: 0;
    right: 0;
    z-index: 1100;
    margin: 2rem 0.5rem;
  }

  .sidemenu-mobile-tab button {
    background-color: transparent;
    border: 0px solid white;
  }

  .sidemenu-mobile-tab button i {
    color: gray;
    font-size: xx-large !important;
    width: 18px;
    height: 18px;
  }

  .sidemenu-default.inactive .sidemenu-mobile-tab {
    display: inline-block !important;
    position: absolute;
    width: 50px;
    top: 0;
    right: -35px !important;
    z-index: 1100;
    margin: 2rem 0.5rem;
  }

  .sidemenu-mask {
    overflow: hidden;
  }

  .sidemenu {
    height: calc(100%);
    background-color: #21335a;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: white #70798c;
    color: white;

    height: 80%;
    overflow-x: hidden;
    width: 100%;
    z-index: 30;

    padding-top: 1rem;
    margin-bottom: 5rem;
  }

  .sidemenu--default .sidemenu-mobile-tab {
    right: 1px !important;
    top: -15px !important;
  }

  .sidemenu--default.active .sidemenu-mobile-tab button {
    transform: translateX(-5px);
  }

  .sidemenu-mobile-tab {
    display: flex !important;
    justify-content: flex-end;
    align-items: flex-start;

    position: fixed;
    width: 100%;

    top: 0 !important;
    right: 0!important;
    z-index: 1100;
  }

  .sidemenu--default .sidemenu-mobile-tab[isopen="false"][ismobiledevice="true"], .sidemenu--default .sidemenu-mobile-tab[isopen="true"][ismobiledevice="true"] {
    position: fixed!important;
    top: 5.6rem !important;
    right: 0rem!important;
    left: 0rem!important;
    width: 100vw!important;
    margin: 0rem!important;
    background-color: transparent!important;
  }

  .sidemenu--default .sidemenu-mobile-tab[isopen="true"][ismobiledevice="true"] {
    left: -13px!important;
  }

  .sidemenu--default .sidemenu-mobile-tab[isopen="false"][ismobiledevice="true"] button {
    background-color: transparent!important;
    border: 0px solid white!important;
  }

  .sidemenu--default .sidemenu-mobile-tab[isopen="false"][ismobiledevice="true"] button i {
    background-color: transparent!important;
    color: #21335a!important;
    font-size: xx-large !important;
    width: 18px;
    height: 18px;
  }

  .sidemenu--default .sidemenu-mobile-tab[isopen="true"][ismobiledevice="true"] {
    display: flex !important;
  }

  .sidemenu-mobile-tab button {
    height: 50px;
    width: 50px;
    background-color: transparent;
    border: 0px;
    cursor: pointer;

    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .sidemenu-mobile-tab button:hover {
    opacity: 0.5;
  }

  .sidemenu-mobile-tab button i {
    color: #21335a;
    font-size: xx-large !important;
    margin: auto 0;
  }

  .sidemenu-search {
    width: -100% !important;
  }
}

#header-center {
  font-size: 24px;
}

.sidemenu--default {
  height: calc(100vh) !important;
  width: 350px;

  position: inherit !important;

  background-color: #21335a;
}

.sidemenu {
  height: calc(100%);
  background-color: #21335a;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: white #70798c;
  color: white;

  height: 80%;
  overflow-x: hidden;
  width: 350px;
  z-index: 30;

  padding-top: 1rem;
  margin-bottom: 5rem;

  animation: slideout 0.5s;
}

.sidemenu--default {
  width: 350px;
  animation: slideout 0.5s;
  margin-left: -350px;
}

.sidemenu--default.active {
  margin-left: 0px;
  /* animation: slidein 0.5s; */
}

@keyframes slidein {
  from {
    margin-left: -350px;
  }
  to {
    margin-left: 0px;
  }
}

@keyframes slideout {
  from {
    margin-left: 0px;
  }
  to {
    margin-left: -350px;
  }
}

.sidemenu-mask {
  padding-bottom: 10rem;
}
.sidemenu-mobile-tab {
  display: none;
}

.sidemenu-mask-item {
  cursor: pointer;
  width: 100% !important;
}

.sidemenu-mask-item.empty {
  opacity: 0.5;
  display: none;
}

.sidemenu-mask-item div {
  cursor: pointer;
  padding: 0.4rem;
  width: 95%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.sidemenu-mask-dropdown {
  cursor: pointer;
  width: 100% !important;
}
.sidemenu-mask-dropdown div {
  cursor: pointer;
  padding: 0.4rem;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.sidemenu-mask-dropdown-list {
  height: 100%;
  display: none;
  overflow: hidden;

  transform: scaleY(0);
  transform-origin: top;

  transition-property: transform;
  transition-duration: 0.05s;
  transition-timing-function: ease-in;
}
.sidemenu-mask-dropdown-list[open="open"] {
  height: 100%;
  max-height: 100%;
  display: block;
  transform-origin: top;
  transform: scaleY(1);

  transition-property: transform;
  transition-duration: 0.05s;
  transition-timing-function: ease-in;
}

.sidemenu-mask-dropdown-list[level="1"] .sidemenu-mask-item {
  padding-left: 0rem;
  margin-left: -0.8rem;
}

.sidemenu-mask-dropdown-list[level="2"] .sidemenu-mask-item {
  padding-left: 2.8rem;
}

.sidemenu-mask-dropdown-list[level="3"] .sidemenu-mask-item {
  padding-left: 4.1rem;
}

.sidemenu-mask-dropdown-list[level="4"] .sidemenu-mask-item {
  padding-left: 5.5rem;
}

.sidemenu-mask-dropdown-list[level="5"] .sidemenu-mask-item {
  padding-left: 6.9rem;
}

.sidemenu-mask-dropdown-list[level="6"] .sidemenu-mask-item {
  padding-left: 8.3rem;
}

.sidemenu-mask-dropdown-list[level="1"] .sidemenu-mask-dropdown {
  padding-left: 1.35rem;
}

.sidemenu-mask-dropdown-list[level="2"] .sidemenu-mask-dropdown {
  padding-left: 2.8rem;
}

.sidemenu-mask-dropdown-list[level="3"] .sidemenu-mask-dropdown {
  padding-left: 4.1rem;
}

.sidemenu-mask-dropdown-list[level="4"] .sidemenu-mask-dropdown {
  padding-left: 5.5rem;
}

.sidemenu-mask-dropdown-list[level="5"] .sidemenu-mask-dropdown {
  padding-left: 6.9rem;
}

.sidemenu-mask-dropdown-list[level="6"] .sidemenu-mask-dropdown {
  padding-left: 8.3rem;
}

.sidemenu-mask-dropdown-list[level="5"]
  .sidemenu-mask-dropdown-list[level="6"]
  .sidemenu-mask-dropdown {
  padding-left: 8rem !important;
}

/* .carrier .sidemenu-mask-dropdown-list[level="1"] .sidemenu-mask-item {
  padding-left:.7rem;
}

.carrier .sidemenu-mask-dropdown-list[level="2"] .sidemenu-mask-item {
  padding-left: 3rem!important;
}

.carrier .sidemenu-mask-dropdown-list[level="3"] .sidemenu-mask-item {
  padding-left: 4.1rem!important;
}

.carrier .sidemenu-mask-dropdown-list[level="4"] .sidemenu-mask-item {
  padding-left: 2.8rem;
}

.carrier .sidemenu-mask-dropdown-list[level="5"] .sidemenu-mask-item {
  padding-left: 5.5rem;
}

.carrier .sidemenu-mask-dropdown-list[level="6"] .sidemenu-mask-item {
  padding-left: 6.7rem;
} */

.carrier .sidemenu-mask-dropdown-list[level="1"] .sidemenu-mask-dropdown {
  padding-left: 1.35rem;
}

.carrier .sidemenu-mask-dropdown-list[level="2"] .sidemenu-mask-dropdown {
  padding-left: 2.7rem;
}

.carrier .sidemenu-mask-dropdown-list[level="3"] .sidemenu-mask-dropdown {
  padding-left: 4.1rem;
}

.carrier .sidemenu-mask-dropdown-list[level="4"] .sidemenu-mask-dropdown {
  padding-left: 4rem;
}

.carrier .sidemenu-mask-dropdown-list[level="5"] .sidemenu-mask-dropdown {
  padding-left: 6rem;
}

.carrier .sidemenu-mask-dropdown-list[level="6"] .sidemenu-mask-dropdown {
  padding-left: 10rem;
}

.sidemenu-mask-dropdown-list[level="5"]
  .sidemenu-mask-dropdown-list[level="6"]
  .sidemenu-mask-dropdown {
  padding-left: 8rem !important;
}

i.fa,
i.fas {
  margin: 5px;
  font-size: 15px !important;
  min-width: 12px !important;
  max-width: 12px !important;
  width: 100% !important;
  padding: 0px !important;
  transition: all 0.15s ease-in-out;
}

.sidemenu-mask > .sidemenu-mask-item i:first-of-type:last-of-type {
  margin-left: -1rem !important;
}

i.open {
  transform: rotate(90deg);
  transition: all 0.15s ease-in-out;
}

i.open {
  transform: rotate(90deg);
  transition: all 0.15s ease-in-out;
}

.sidemenu-mask-item > div:first-child:not(i.fa),
.sidemenu-mask-item > div:first-child:not(i.fas) {
  padding-left: 2.6rem;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* .carrier .sidemenu-mask-dropdown-list[level="1"] .sidemenu-mask-item {
  padding-left:.7rem;
}

.carrier .sidemenu-mask-dropdown-list[level="2"] .sidemenu-mask-item {
  padding-left: 3rem!important;
}

.carrier .sidemenu-mask-dropdown-list[level="3"] .sidemenu-mask-item {
  padding-left: 6.1rem!important;
}

.carrier .sidemenu-mask-dropdown-list[level="4"] .sidemenu-mask-item {
  padding-left: 4rem;
}

.carrier .sidemenu-mask-dropdown-list[level="5"] .sidemenu-mask-item {
  padding-left: 5.5rem;
}

.carrier .sidemenu-mask-dropdown-list[level="6"] .sidemenu-mask-item {
  padding-left: 6.7rem;
} */

  .carrier .sidemenu-mask-dropdown-list[level="1"] .sidemenu-mask-dropdown {
    padding-left: 1.35rem;
  }

  .carrier .sidemenu-mask-dropdown-list[level="2"] .sidemenu-mask-dropdown {
    padding-left: 2.55rem;
  }

  .carrier .sidemenu-mask-dropdown-list[level="3"] .sidemenu-mask-dropdown {
    padding-left: 3.9rem;
  }

  .carrier .sidemenu-mask-dropdown-list[level="4"] .sidemenu-mask-dropdown {
    padding-left: 4rem;
  }

  .carrier .sidemenu-mask-dropdown-list[level="5"] .sidemenu-mask-dropdown {
    padding-left: 6rem;
  }

  .carrier .sidemenu-mask-dropdown-list[level="6"] .sidemenu-mask-dropdown {
    padding-left: 10rem;
  }

  .sidemenu-mask > .sidemenu-mask-item i:first-of-type:last-of-type {
    margin-left: 0rem !important;
  }

  sidemenu-mask > .sidemenu-mask-item {
    padding-left: 1.2rem !important;
  }

  .sidemenu-mask-dropdown-list[level="1"] .sidemenu-mask-item {
    padding-left: 2.5rem;
  }

  .sidemenu-mask-dropdown-list[level="2"] .sidemenu-mask-item {
    padding-left: 3.3rem;
  }

  .sidemenu-mask-dropdown-list[level="3"] .sidemenu-mask-item {
    padding-left: 4.55rem;
  }

  .sidemenu-mask-dropdown-list[level="4"] .sidemenu-mask-item {
    padding-left: 5.55rem;
  }

  .sidemenu-mask-dropdown-list[level="5"] .sidemenu-mask-item {
    padding-left: 9.1rem;
  }

  .sidemenu-mask-dropdown-list[level="6"] .sidemenu-mask-item {
    padding-left: 10.7rem;
  }

  .sidemenu-mask-dropdown-list[level="1"] .sidemenu-mask-dropdown {
    padding-left: 1.2rem;
  }

  .sidemenu-mask-dropdown-list[level="2"] .sidemenu-mask-dropdown {
    padding-left: 2.4rem;
  }

  .sidemenu-mask-dropdown-list[level="3"] .sidemenu-mask-dropdown {
    padding-left: 3.4rem;
  }

  .sidemenu-mask-dropdown-list[level="4"] .sidemenu-mask-dropdown {
    padding-left: 6.2rem;
  }

  .sidemenu-mask-dropdown-list[level="5"] .sidemenu-mask-dropdown {
    padding-left: 7.9rem;
  }

  .sidemenu-mask-dropdown-list[level="6"] .sidemenu-mask-dropdown {
    padding-left: 10rem;
  }

  .sidemenu-mask-dropdown-list[level="5"]
    .sidemenu-mask-dropdown-list[level="6"]
    .sidemenu-mask-dropdown {
    padding-left: 8rem !important;
  }
}

.sidemenu-search {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100px;
  width: 100;
  background-color: #ebebeb;
  border-bottom: 2.5px solid #70798c;
}

.nav-btn {
  height: 45.8px;
  position: relative;
  width: 45.8px!important;
  transform: translateX(-18px);
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  border: 0px;
  cursor: pointer;
}

.sidemenu--default .sidemenu-mobile-tab[isopen="false"] {
  top: 1.65rem!important;
  left: 1rem!important;
}
.sidemenu-mobile-tab[isopen="false"], .sidemenu-mobile-tab[isopen="false"] .nav-btn, .sidemenu-mobile-tab[isopen="false"] .nav-btn i {
  background-color: #d9d9d9!important;
}

button.nav-btn:hover, button.nav-btn:hover i {
  background-color: #ebebeb;
  color: #21335aa4;
}

.nav-btn i {
  color: #21335a;
  font-size: 2rem!important;
}

@media (min-width: 801px) {
  .sidemenu-search {
    width: 350px;
  }
}

.sidemenu--default,
.sidemenu--default.active,
.sidemenu--default.inactive {
  width: 350px;
  position: relative !important;
}

.sidemenu-search-box {
  width: 75%;
}

.sidemenu-search-box .icon {
  position: absolute;
  margin: 0.5rem;
  color: #b0b0b0;
}

.sidemenu-search-box input {
  width: 100%;
  padding: 0.65rem;
  padding-left: 2rem;
  border: 2px solid #b0b0b0;
}

#app-body-area[activelayout="osr-facing"],
#app-body-area[activelayout="client-facing"] {
  background-color: #d9d9d9 !important;
}

@media (max-width: 1000px) {
  #body-content-area-all {
    width: 100% !important;
    padding: 1rem;
  }

  #content-area-full {
    width: 100% !important;
    background-color: white !important;
  }

  #app-body-area[activelayout="client-facing"] #content-area-full,
  #app-body-area[activelayout="osr-facing"] #content-area-full {
    width: 100% !important;
    right: left !important;
    left: auto;
    background-color: #d9d9d9 !important;
    margin-top: .7rem!important;
  }
}

.header--container.is-open ~ #app-body-area[activelayout="osr-facing"] #content-area-full,
.header--container.is-open ~ #app-body-area[activelayout="client-facing"] #content-area-full{
  margin-left: 0 !important;
}

#app-body-area[activelayout="client-facing"] {
  top: 92px;
  width: 100%;
  left: 0;
  background-color: #21335a;
  margin-left: auto;
}

@media (min-width: 1001px) {
  .mobile-toggle {
    visibility: hidden;
  }
}

#body-content-area-all {
  /* width: 80%; */
  overflow-y: hidden;
}

#pagelayout {
  padding: 0.5rem;
}

#content-area-full {
  background-color: #d9d9d9;
}

#app-body-area[activelayout="client-facing"] #content-area-full , #app-body-area[activelayout="osr-facing"] #content-area-full {
  margin-left: 3rem!important;
}s

#body-content-area-all {
  background-color: white;
  position: relative !important;
  width: 90% !important;
  margin: 1rem;
  margin-left: auto;
  margin-right: auto;

  overflow-y: scroll;
  overflow-x: hidden;

  scrollbar-width: none;
}

#app-body-area[activelayout="client-facing"] #body-content-area-all {
  height: calc(100vh - 86px - 92px);
  /* margin-left: auto; */
}

.modal-background {
  z-index: 40;
}

.modal-card {
  z-index: 50;
}
#app-body-area[activelayout="osr-facing"] #body-content-area-all {
  height: calc(100vh - 52px - 86px - 92px);
  /* margin-left: auto; */
}

#app-body-area[activelayout="client-facing"] {
  top: 92px;
  left: 0 !important;
  background-color: #21335a;
}

#app-body-area[activelayout="administrative"] #body-content-area-all {
  height: calc(100vh - 2rem - 86px - 92px);
}

#footer-area {
  z-index: 40 !important;
}

.sidemenu-mobile-tab[isopen="true"] {
  display: none!important;
}

.sidemenu--default .sidemenu-mobile-tab {
  left: 1.75rem !important;
  top: 1.2rem !important;
}

.sidemenu-mobile-tab {
  display: flex !important;
  justify-content: flex-end;
  align-items: center;

  position: absolute;
  width: 400px;
  right: 80px!important;
  z-index: 1100;
}

.sidemenu-mobile-tab button {
  width: 32px;
  aspect-ratio: 1/1;
  padding: 0.25rem;
  border: 0px;
  color: white;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.sidemenu-mobile-tab:hover {
  opacity: 0.8;
}

/* .sidemenu-mobile-tab {
  display: inline-block!important;
  width: 50px;
  top: 0;
  right: 0;
  z-index: 1100;
  margin: 2rem .5rem;
} */

@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css";

.tooltip {
  display: block !important;
  text-overflow: wrap;
  white-space: wrap;
  max-width: 300px;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.header--container.is-closed ~ #app-body-area {
  width: 100%;
  /* animation: grow 0.5s ease-in-out !important; */
}

.header--container.is-open ~ #app-body-area {
  width: 80%;
  /* animation: shrink 0.5s ease-in-out !important; */
}

@keyframes grow {
  0% {
    width: 80% !important;
  }
  100% {
    width: 100% !important;
  }
}

@keyframes shrink {
  0% {
    width: 100% !important;
  }
  100% {
    width: 80% !important;
  }
}
</style>
<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.search {
  font-family: "Poppins", sans-serif !important;
}

i.fa.hidden,
i.fas.hidden {
  visibility: hidden;
  margin-right: 9px;
}

.invisible {
  opacity: 0;
}
</style>
