var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:((_vm.display && _vm.carriers) || _vm.isMenuItemType('link')),expression:"(display && carriers) || isMenuItemType('link')"}],attrs:{"type":_vm.item.data.ItemType}},[(_vm.isMenuItemType('group') && _vm.isValidGroup)?_c('div',[_c('menu-item-folder',{class:_vm.isLoading ? 'menuitem-loading' : '',attrs:{"item":_vm.item,"level":_vm.level,"open":_vm.isOpen,"sidemenu":_vm.sidemenu,"tooltipConfig":_vm.tooltipConfig,"isLoad":_vm.isLoading,"type":"group"},on:{"onOpenChange":_vm.onOpenChange}}),(_vm.isOpen)?_c('menu-item-folder-dropdown',{attrs:{"item":_vm.item,"level":_vm.level,"reportCodeCounts":_vm.reportCodeCounts,"carriers":_vm.carriers,"activeSite":_vm.activeSite,"activeUser":_vm.activeUser,"open":_vm.isOpen,"isLoad":_vm.isLoading,"years":{
        menuItemId: _vm.item.id,
        years: _vm.years
      }},on:{"years":_vm.onYearsUpdate}}):_vm._e()],1):(_vm.isMenuItemType('dynamic Carrier') && _vm.carrierIsvalid())?_c('div',[_c('div',{ref:_vm.item.id,class:['sidemenu-mask-dropdown', _vm.isLoading ? 'menuitem-loading' : ''],attrs:{"id":_vm.item.id,"children":_vm.numCarriers,"parent":_vm.item.data.ParentId},on:{"click":function ($event) { return (_vm.isOpen = !_vm.isOpen); },"mouseover":function ($event) { return _vm.ellipsis($event, true); },"mouseleave":function ($event) { return _vm.ellipsis($event, false); }}},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
          content: _vm.item.data.Label,
          trigger: 'manual',
          boundariesElement: _vm.sidemenu,
        }),expression:"{\n          content: item.data.Label,\n          trigger: 'manual',\n          boundariesElement: sidemenu,\n        }",modifiers:{"top-center":true}}],staticClass:"tooltip-hover",attrs:{"open":false}},[_c('i',{class:[
            'status-icon fa fa-chevron-right',
            _vm.isOpen ? 'open' : 'closed' ]}),_c('i',{class:_vm.getIcon(_vm.item.data)}),_vm._v(" "+_vm._s(_vm.item.data.Label)+" ")])]),_c('div',{ref:_vm.item.id,staticClass:"sidemenu-mask-dropdown-list",attrs:{"data-controlled-by":_vm.item.id,"open":_vm.isOpen,"level":_vm.getNextLevel() - 2,"type":_vm.item.data.ReportCode}},_vm._l((_vm.validCarriers),function(carrier){return _c('div',{key:carrier.code},[_c('SidemenuCarrierItem',{attrs:{"reportTypeCodes":_vm.reportTypeCodes,"activeSite":_vm.activeSite,"activeUser":_vm.activeUser,"carriers":_vm.carriers,"reportCodeCounts":_vm.reportCodeCounts,"level":_vm.getNextLevel(),"item":carrier},on:{"update":_vm.update,"is-invalid":function($event){return _vm.handleEvent(-1)},"is-valid":function($event){return _vm.handleEvent(1)}}})],1)}),0)]):(_vm.isMenuItemType('vendor'))?_c('div',[_c('menu-item-folder',{attrs:{"item":_vm.item,"level":_vm.level,"open":_vm.isOpen,"sidemenu":_vm.sidemenu,"tooltipConfig":_vm.tooltipConfig},on:{"onOpenChange":_vm.onOpenChange}}),(_vm.isOpen)?_c('menu-item-folder-dropdown',{attrs:{"item":_vm.item,"level":_vm.level,"reportCodeCounts":_vm.reportCodeCounts,"carriers":_vm.carriers,"activeSite":_vm.activeSite,"activeUser":_vm.activeUser,"open":_vm.isOpen,"isLoad":_vm.isLoading,"years":{
        menuItemId: _vm.item.id,
        years: _vm.years
      }},on:{"years":_vm.onYearsUpdate}}):_vm._e()],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.isLink && ((_vm.item.linkRequiresPermission && _vm.hasPermission('USR')) || !_vm.item.linkRequiresPermission)),expression:"item.isLink && ((item.linkRequiresPermission && hasPermission('USR')) || !item.linkRequiresPermission)"}]},[_c('div',{ref:_vm.item.id,class:['sidemenu-mask-dropdown', _vm.isLoading ? 'menuitem-loading' : ''],attrs:{"id":_vm.item.id,"children":5,"parent":_vm.item.data.ParentId},on:{"click":function($event){$event.stopPropagation();return _vm.navigate(_vm.item.data)},"mouseover":function($event){return _vm.ellipsis($event, true)},"mouseleave":function($event){return _vm.ellipsis($event, false)}}},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({ content: _vm.item.data.Label, trigger: 'manual', boundariesElement: _vm.sidemenu }),expression:"{ content: item.data.Label, trigger: 'manual', boundariesElement: sidemenu }",modifiers:{"top-center":true}}],staticClass:"tooltip-hover",attrs:{"open":_vm.isOpen}},[_c('i',{class:_vm.getIcon(_vm.item.data)}),_vm._v(" "+_vm._s(_vm.item.data.Label)+" ")])])]),(_vm.isMenuItemType('reports'))?_c('div',[(!_vm.reports && !_vm.isEmpty)?_c('menu-item-loader',{attrs:{"level":_vm.level + 1,"menuItem":_vm.item}}):(_vm.reports && !_vm.isEmpty)?_c('menu-item-reports',{attrs:{"reports":_vm.reports,"menuItem":_vm.item}}):_c('div',[_c('div',{staticClass:"sidemenu-mask-dropdown-list",attrs:{"open":true,"level":_vm.getNextLevel()}},[_c('div',{staticClass:"sidemenu-mask-item",attrs:{"level":_vm.level + 1}},[_vm._v(" No files found ")])])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }