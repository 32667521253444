<template>
  <div>

    <div v-if="activeLayout === 'osr-facing' || activeLayout === 'client-facing'">
      <div id="footer-area" style="padding: 10px 25px;" class="client has-background-white">
        <div id="footer-text" style="width: 100px;" class="is-size-7-mobile" >
          <span class="has-text-dark" style="font-size: 13px!important">
            &copy; {{ new Date() | moment("YYYY") }}
          </span>
        </div>

        <div id="is-powered-by">
          <i style="font-size: 10px!important">Powered by</i>
          <img style="max-height: 40px; width: auto" :src="activeSite.logoUrl">
        </div>
      </div>
    </div>

    <div v-if="activeLayout !== 'osr-facing' && activeLayout !== 'client-facing'">
      <div id="footer-area" class="admin has-background-primary">
        <div id="footer-text" class="has-text-centered is-size-7-mobile">
          <p style="padding: 15px;">
            <span class="has-text-white">
              &copy; {{ new Date() | moment("YYYY") }} {{ activeSite.displayName }}
            </span>
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'

export default {
  name: 'sitefooter',
  props: {
    activeLayout: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState([activeSite])
  }
}
</script>

<style>
#footer-area {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

#footer-area.client {
  border-top: 2px solid rgba(0,0,0,0.1);
}

#footer-area.admin {
  display: block!important;
}

#footer-area.admin #footer-text p {
  text-align: center;
  width: 100%;
}

#footer-area div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;

  height: 100%;
}

#is-powered-by {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
}

#is-powered-by img {
  width: 150px;
  margin-left: 2rem;
}

@media (max-width: 700px) {
  #is-powered-by {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #is-powered-by img {
    width: 100px;
    margin-top: 1rem;
    margin-left: 1rem;
    padding-bottom: .75rem;
  }
}
</style>
