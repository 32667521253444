import Vue from 'vue'
import Router from 'vue-router'
import { store } from './store'
import { STAFF, MANAGER, OSR, CLIENT, TEAM } from './roles'

// lazy loading
const Reports = () => import('@/components/Reports')
const EditReport = () => import('@/components/EditReport')
const ViewReport = () => import('@/components/ViewReport')
const NewReport = () => import('@/components/NewReport')
const Questionnaires = () => import('@/components/Questionnaires')
const NewQuestionnaire = () => import('@/components/NewQuestionnaire')
const QuestionnaireHistory = () => import('@/components/QuestionnaireHistory')
const EmailTemplate = () => import('@/components/EmailTemplates')
const NewEmailTemplate = () => import('@/components/NewEmailTemplate')
const EditEmailTemplate = () => import('@/components/EditEmailTemplate')
const Institutions = () => import('@/components/Institutions')
const EditInstitution = () => import('@/components/EditInstitution')
const ReportTypes = () => import('@/components/ReportTypes')
const EditReportType = () => import('@/components/EditReportType')
const Sites = () => import('@/components/Sites')
const EditSite = () => import('@/components/EditSite')
const Users = () => import('@/components/Users')
const EditUser = () => import('@/components/EditUser')
const Settings = () => import('@/components/Settings')
const Login = () => import('./components/Login')
// const Layout = () => import('./components/Layout')
const MyAccount = () => import('./components/MyAccount')
const MyDashboard = () => import('./components/MyDashboard')
const MyServiceTeam = () => import('./components/MyServiceTeam')
const ServiceRequest = () => import('./components/ServiceRequest')
const MyProfile = () => import('./components/MyProfile')
const ActivityReport = () => import('./components/ActivityReport')
const ChangePassword = () => import('./components/ChangePassword')
const ClientMenuManagement = () => import('@/components/ClientMenuManagement')
const ClientMenuItemEdit = () => import('@/components/ClientMenuItemEdit')
const SearchScreen = () => import('@/components/SearchScreen')
const BankUploadsScreen = () => import('@/components/BankUploadsScreen')
const IEBankUploadsScreen = () => import('@/components/IEBankUploadsScreen')
const InstutionsDocumentsScreen = () => import('@/components/InstutionsDocumentsScreen')
const ViewReportAdminScreen = () => import('@/components/ViewReportAdminScreen')
const DocumentsCenterScreen = () => import('@/components/DocumentsCenterScreen')
const DocumentsCenterEditScreen = () => import('@/components/DocumentsCenterEditScreen')
const DocumentCenterCreateScreen = () => import('@/components/DocumentCenterCreateScreen')
const CarrierManagementScreen = () => import('@/components/CarrierManagementScreen')
const CarrierManagementEditScreen = () => import('@/components/CarrierManagementEditScreen')
const ViewDocumentScreen = () => import('@/components/ViewDocumentScreen')
const TwoFactorAuthenticationScreen = () => import('@/components/2faScreen.vue')
const TwoFactorVerifyScreen = () => import('@/components/2faVerifyScreen.vue')
const AnnouncementsScreen = () => import('@/components/AnnouncementsScreen')
const AnnouncementDetailsScreen = () => import('@/components/AnnouncementDetailsScreen')
const LogoutPage = () => import('@/components/Logout.vue')
// const DashboardPage = () => import('@/DashboardPage.vue')
const PermissionTemplatePage = () => import('@/PermissionTemplatePage.vue')
const PermissionTemplateEditPage = () => import('@/PermissionTemplateEditPage.vue')
const NotAuthorized = () => import('@/components/NotAuthorized.vue')

// Asset Reports
const ARListPage = () => import('@/components/asset-reporting/ListPage.vue')
const ARCreatePage = () => import('@/components/asset-reporting/CreatePage.vue')
const ARBatchViewPage = () => import('@/components/asset-reporting/BatchViewPage.vue')
const ARBatchReportPreviewPage = () => import('@/components/asset-reporting/BatchReportPreviewPage.vue')

const ARSummaryListPage = () => import('@/components/asset-summary-reporting/ListPage.vue')
const ARSummaryCreatePage = () => import('@/components/asset-summary-reporting/CreatePage.vue')
const ARSummaryBatchViewPage = () => import('@/components/asset-summary-reporting/BatchViewPage.vue')
const ARSummaryBatchReportPreviewPage = () => import('@/components/asset-summary-reporting/BatchReportPreviewPage.vue')

// 8925 Reports
const C8925ListPage = () => import('@/components/8925-reporting/ListPage.vue')
const C8925CreatePage = () => import('@/components/8925-reporting/CreatePage.vue')
const C8925BatchViewPage = () => import('@/components/8925-reporting/BatchViewPage.vue')
const C8925BatchReportPreviewPage = () => import('@/components/8925-reporting/BatchReportPreviewPage.vue')

// Annual Questionnaire
const AnnualQuestionnaireListPage = () => import('@/components/annual-questionnaire/ListPage.vue')
const AnnualQuestionnaireCreatePage = () => import('@/components/annual-questionnaire/CreatePage.vue')
const AnnualQuestionnaireBatchViewPage = () => import('@/components/annual-questionnaire/BatchViewPage.vue')
// const AnnualQuestionnaireBatchReportPreviewPage = () => import('@/components/annual-questionnaire/BatchReportPreviewPage.vue')
const AnnualQuestionnaireCreateBatchPage = () => import('@/components/annual-questionnaire/CreateBatchPage.vue')
const AnnualQuestionnaireClientPage = () => import('@/components/annual-questionnaire/ClientSide/AnnualQuestionnairePage.vue')

// Annual BOLI Review
const AnnualBOLIReviewListPage = () => import('@/components/annual-review/ListPage.vue')
const AnnualBOLIReviewCreatePage = () => import('@/components/annual-review/CreatePage.vue')
const AnnualBOLIReviewBatchViewPage = () => import('@/components/annual-review/BatchViewPage.vue')
const AnnualBOLIBatchReportPreviewPage = () => import('@/components/annual-review/BatchReportPreviewPage.vue')
const AnnualBOLIReviewCreateBatchPage = () => import('@/components/annual-review/CreateBatchPage.vue')
const AnnualBOLIReportConfigPage = () => import('@/components/annual-review/ReportConfigScreen.vue')

// Configuration Screens
const ReportConfigurationScreen = () => import('@/components/configuration/ReportConfigurationScreen.vue')
const AnnualBOlIReviewConfigurationScreen = () => import('@/components/configuration/AnnualBoliReviewConfigScreen.vue')

Vue.use(Router)

let router = new Router({
  routes: [
    {
      path: '/error/:code',
      name: 'not-authorized',
      component: NotAuthorized,
      props: true,
      meta: {
        isPublic: true
      }
    },
    {
      path: '/change-password',
      name: 'ChangePassword',
      component: ChangePassword,
      props: true,
      meta: {
        isPublic: true
      }
    },
    {
      path: '/staff/dashboard',
      name: 'staffDashboard',
      component: () => import('@/DashboardPage.vue'),
      props: true,
      meta: {
        isPublic: false
      }
    },
    {
      path: '/configuration/reports',
      name: 'report-configuration',
      component: ReportConfigurationScreen,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER],
        isPublic: false
      }
    },
    {
      path: '/configuration/boli-review',
      name: 'BOLI-Review-Configuration',
      component: AnnualBOlIReviewConfigurationScreen,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER],
        isPublic: false
      }
    },
    {
      path: '/configuration/coli-review',
      name: 'COLI-Review-Configuration',
      component: AnnualBOlIReviewConfigurationScreen,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER],
        isPublic: false
      }
    },
    {
      path: '/questionnaires/annual',
      name: 'annual-questionnaire-client',
      component: AnnualQuestionnaireClientPage,
      props: true,
      meta: {
        allowedRoles: [CLIENT, OSR],
        isPublic: false
      }
    },
    {
      path: '/configuration/annual-questionnaire',
      name: 'report-configuration-annual-questionnaire',
      component: () => import('@/components/configuration/AnnualQuestionnaireConfigScreen.vue'),
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER],
        isPublic: false
      }
    },
    {
      name: 'StateConfigEdit',
      path: '/configuration/reports/states/:id',
      component: () => import('@/components/configuration/StateConfigEdit.vue'),
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER],
        isPublic: false
      }
    },
    {
      name: 'NewStateConfig',
      path: '/configuration/reports/states',
      component: () => import('@/components/configuration/StateConfigEdit.vue'),
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER],
        isPublic: false
      }
    },
    {
      path: '/logout',
      name: 'Logout',
      component: LogoutPage,
      props: true,
      meta: {
        isPublic: false
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        isPublic: true
      }
    },
    {
      path: '/2fa-verify',
      name: '2FAVerify',
      component: TwoFactorVerifyScreen,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM]
      }
    },
    {
      path: '/2fa-setup',
      name: '2FASetup',
      component: TwoFactorAuthenticationScreen,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM]
      }
    },
    {
      path: '/my-account',
      name: 'MyAccount',
      component: MyAccount,
      meta: {
        allowedRoles: [OSR, CLIENT, TEAM]
      },
      redirect: '/dashboard'
    },
    {
      path: '/bank-uploads',
      name: 'BankUploads',
      component: BankUploadsScreen,
      exposeOptions: true,
      meta: {
        allowedRoles: [OSR, CLIENT]
      }
    },
    {
      path: '/ie-bank-uploads',
      name: 'IEBankUploads',
      component: IEBankUploadsScreen,
      exposeOptions: false,
      meta: {
        allowedRoles: [OSR, CLIENT]
      }
    },
    {
      path: '/search',
      name: 'Search',
      component: SearchScreen,
      props: true,
      meta: {
        allowedRoles: [OSR, CLIENT]
      }
    },
    {
      path: '/permissions/templates',
      name: 'PermissionTemplate',
      component: PermissionTemplatePage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, TEAM],
        permissions: ['PERMISSION_TEMPLATES']
      }
    },
    {
      path: '/permissions/templates/new',
      name: 'PermissionTemplateNewPage',
      component: PermissionTemplateEditPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, TEAM],
        permissions: ['PERMISSION_TEMPLATES'],
        minPermissionLevel: 2
      }
    },
    {
      path: '/permissions/templates/:id',
      name: 'PermissionTemplateEditPage',
      component: PermissionTemplateEditPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, TEAM],
        permissions: ['PERMISSION_TEMPLATES']
      }
    },
    {
      path: '/announcement-management',
      name: 'Announcements',
      component: AnnouncementsScreen,
      props: false,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['ANNOUNCEMENTS']
      }
    },
    {
      path: '/announcement-details/new',
      name: 'AnnouncementDetailsNew',
      component: AnnouncementDetailsScreen,
      props: true,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['ANNOUNCEMENTS'],
        minPermissionLevel: 2
      }
    },
    {
      path: '/announcement-details/:id',
      name: 'AnnouncementDetails',
      component: AnnouncementDetailsScreen,
      props: true,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['ANNOUNCEMENTS']
      }
    },
    {
      path: '/asset-reporting',
      name: 'AssetReporting',
      component: ARListPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ASSET_REPORT', 'ASSET_REPORT_READ', 'ASSET_REPORT_WRITE']
      }
    },
    {
      path: '/asset-reporting/generate',
      name: 'AssetReportingGenerate',
      component: ARCreatePage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ASSET_REPORT']
      }
    },
    {
      path: '/asset-reporting/:id',
      name: 'AssetReportingBatchView',
      component: ARBatchViewPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ASSET_REPORT']
      }
    },
    {
      path: '/asset-reporting/:id/report/:reportId',
      name: 'ARBatchReportPreview',
      component: ARBatchReportPreviewPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ASSET_REPORT']
      }
    },
    {
      path: '/summary-asset-reporting',
      name: 'AssetSummaryReporting',
      component: ARSummaryListPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ASSET_SUMMARY_REPORT']
      }
    },
    {
      path: '/summary-asset-reporting/generate',
      name: 'AssetSummaryReportingGenerate',
      component: ARSummaryCreatePage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ASSET_SUMMARY_REPORT']
      }
    },
    {
      path: '/summary-asset-reporting/:id',
      name: 'AssetSummaryReportingBatchView',
      component: ARSummaryBatchViewPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ASSET_SUMMARY_REPORT']
      }
    },
    {
      path: '/summary-asset-reporting/:id/report/:reportId',
      name: 'ARSummaryBatchReportPreview',
      component: ARSummaryBatchReportPreviewPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ASSET_SUMMARY_REPORT']
      }
    },
    {
      path: '/8925-reporting',
      name: '8925Reporting',
      component: C8925ListPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['C8925_REPORT']
      }
    },
    {
      path: '/8925-reporting/generate',
      name: '8925ReportingGenerate',
      component: C8925CreatePage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['C8925_REPORT']
      }
    },
    {
      path: '/8925-reporting/:id',
      name: '8925ReportingBatchView',
      component: C8925BatchViewPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['C8925_REPORT']
      }
    },
    {
      path: '/8925-reporting/:id/report/:reportId',
      name: '8925ReportingBatchPreview',
      component: C8925BatchReportPreviewPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['C8925_REPORT']
      }
    },
    {
      path: '/annual-questionnaire',
      name: 'AnnualQuestionnaire',
      component: AnnualQuestionnaireListPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ANNUAL_QUESTIONNAIRE']
      }
    },
    {
      path: '/annual-questionnaire/generate',
      name: 'AnnualQuestionnaireGenerate',
      component: AnnualQuestionnaireCreateBatchPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ANNUAL_QUESTIONNAIRE']
      }
    },
    {
      path: '/annual-questionnaire/:id',
      name: 'AnnualQuestionnaireBatchView',
      component: AnnualQuestionnaireBatchViewPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ANNUAL_QUESTIONNAIRE']
      }
    },
    {
      path: '/annual-questionnaire/:id/results/:reportId',
      name: 'AnnualQuestionnaireBatchReportPreviewPage',
      component: AnnualQuestionnaireCreatePage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ANNUAL_QUESTIONNAIRE']
      }
    },
    {
      path: '/annual-review/:id/results/:reportId',
      name: 'AnnualBOLIBatchReportPreviewPage',
      component: AnnualBOLIBatchReportPreviewPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ANNUAL_BOLI_REVIEW']
      }
    },
    {
      path: '/annual-review',
      name: 'AnnualBOLIReviewListPage',
      component: AnnualBOLIReviewListPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ANNUAL_BOLI_REVIEW']
      }
    },
    {
      path: '/annual-review/generate',
      name: 'AnnualBOLIReviewGenerate',
      component: AnnualBOLIReviewCreateBatchPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ANNUAL_BOLI_REVIEW']
      }
    },
    {
      path: '/annual-review/:id',
      name: 'AnnualBOLIReviewBatchView',
      component: AnnualBOLIReviewBatchViewPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ANNUAL_BOLI_REVIEW']
      }
    },
    {
      path: '/annual-review/:id/results/:reportId/config',
      name: 'AnnualBOLIReportConfigPage',
      component: AnnualBOLIReportConfigPage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ANNUAL_BOLI_REVIEW']
      }
    },
    {
      path: '/annual-review/:id/results/:reportId',
      name: 'AnnualBOLIReviewBatchReportPreviewPage',
      component: AnnualBOLIReviewCreatePage,
      props: true,
      meta: {
        allowedRoles: [STAFF, MANAGER, OSR, CLIENT, TEAM],
        permissions: ['ANNUAL_BOLI_REVIEW']
      }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: MyDashboard,
      meta: {
        allowedRoles: [OSR, CLIENT, TEAM]
      }
    },
    {
      path: '/service-team',
      name: 'MyServiceTeam',
      component: MyServiceTeam,
      exposeOptions: true,
      meta: {
        allowedRoles: [OSR, CLIENT, TEAM]
      }
    },
    {
      path: '/service-request',
      name: 'ServiceRequest',
      exposeOptions: true,
      component: ServiceRequest,
      meta: {
        allowedRoles: [OSR, CLIENT, TEAM]
      }
    },
    {
      path: '/my-profile',
      name: 'MyProfile',
      component: MyProfile,
      meta: {
        // allowedRoles: all
      }
    },
    {
      path: '/reports',
      name: 'Reports',
      component: Reports,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['CLIENT_REPORTS']
      }
    },
    {
      path: '/reports/new',
      name: 'NewReport',
      component: NewReport,
      props: false,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['CLIENT_REPORTS'],
        minPermissionLevel: 2
      }
    },
    {
      path: '/reports/:id',
      name: 'EditReport',
      component: EditReport,
      props: true,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['CLIENT_REPORTS']
      }
    },
    {
      path: '/reports/:id/view',
      name: 'ViewReport',
      component: ViewReport,
      props: true,
      meta: {
        allowedRoles: [CLIENT, OSR, STAFF, MANAGER],
        permissions: ['CLIENT_REPORTS']
      }
    },
    {
      path: '/reports/:id/view',
      name: 'ViewReportAdminScreen',
      component: ViewReportAdminScreen,
      props: true,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['CLIENT_REPORTS']
      }
    },
    {
      path: '/carrier-management',
      name: 'CarrierManagementScreen',
      component: CarrierManagementScreen,
      props: false,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['CARRIERS']
      }
    },
    {
      path: '/carrier-management/new',
      name: 'CarrierManagementNewScreen',
      component: CarrierManagementEditScreen,
      props: true,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['CARRIERS'],
        minPermissionLevel: 2
      }
    },
    {
      path: '/carrier-management/:code',
      name: 'CarrierManagementEditScreen',
      component: CarrierManagementEditScreen,
      props: true,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['CARRIERS']
      }
    },
    {
      path: '/client-menu',
      name: 'ClientMenuManagement',
      component: ClientMenuManagement,
      props: true,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['CLIENT_MENU']
      }
    },
    {
      path: '/client-menu/new',
      name: 'newMenuItem',
      component: ClientMenuItemEdit,
      props: true,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['CLIENT_MENU'],
        minPermissionLevel: 2
      }
    },
    {
      path: '/client-menu/:id/edit',
      name: 'EditMenuItem',
      component: ClientMenuItemEdit,
      props: true,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['CLIENT_MENU']
      }
    },
    {
      path: '/questionnaires',
      name: 'Questionnaires',
      component: Questionnaires,
      props: true,
      meta: {
        allowedRoles: [STAFF]
      }
    },
    {
      path: '/questionnaires/new',
      name: 'NewQuestionnaire',
      component: NewQuestionnaire,
      props: false,
      meta: {
        allowedRoles: [STAFF]
      }
    },
    {
      path: '/questionnaires/:id/history',
      name: 'QuestionnaireHistory',
      component: QuestionnaireHistory,
      props: false,
      meta: {
        allowedRoles: [STAFF]
      }
    },
    {
      path: '/questionnaires/:id',
      name: 'EditQuestionnaire',
      component: NewQuestionnaire,
      props: false,
      meta: {
        allowedRoles: [STAFF]
      }
    },
    {
      path: '/email-templates',
      name: 'EmailTemplates',
      component: EmailTemplate,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['EMAIL_TEMPLATES']
      }
    },
    {
      path: '/email-templates/new',
      name: 'NewEmailTemplate',
      component: NewEmailTemplate,
      props: false,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['EMAIL_TEMPLATES'],
        minPermissionLevel: 2
      }
    },
    {
      path: '/email-templates/:id',
      name: 'EditEmailTemplate',
      component: EditEmailTemplate,
      props: true,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['EMAIL_TEMPLATES']
      }
    },
    {
      path: '/institutions',
      name: 'Institutions',
      component: Institutions,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['INSTITUTIONS']
      }
    },
    {
      path: '/institutions/new',
      name: 'NewInstitution',
      component: EditInstitution,
      props: false,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['INSTITUTIONS'],
        minPermissionLevel: 2
      }
    },
    {
      path: '/institutions/:id',
      name: 'EditInstitution',
      component: EditInstitution,
      props: true,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['INSTITUTIONS']
      }
    },
    {
      path: '/institutions/:id/documents',
      name: 'InstutionsDocumentsScreen',
      component: InstutionsDocumentsScreen,
      props: true,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['DOCUMENT_CENTER', 'INSTITUTIONS']
      }
    },
    {
      path: '/report-types',
      name: 'ReportTypes',
      component: ReportTypes,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['REPORT_TYPES']
      }
    },
    {
      path: '/report-types/new',
      name: 'NewReportType',
      component: EditReportType,
      props: false,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['REPORT_TYPES'],
        minPermissionLevel: 2
      }
    },
    {
      path: '/report-types/:code',
      name: 'EditReportType',
      component: EditReportType,
      props: true,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['REPORT_TYPES']
      }
    },
    {
      path: '/sites',
      name: 'Sites',
      component: Sites,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['SITES']
      }
    },
    {
      path: '/sites/new',
      name: 'NewSite',
      component: EditSite,
      props: false,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['SITES'],
        minPermissionLevel: 2
      }
    },
    {
      path: '/sites/:id',
      name: 'EditSite',
      component: EditSite,
      props: true,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['SITES']
      }
    },
    {
      path: '/users',
      name: 'Users',
      component: Users,
      children: [],
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['USERS']
      }
    },
    {
      path: '/users/new',
      name: 'NewUser',
      component: EditUser,
      props: false,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['USERS'],
        minPermissionLevel: 2
      }
    },
    {
      path: '/users/:id',
      name: 'EditUser',
      component: EditUser,
      props: true,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['USERS']
      }
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
      meta: {
        allowedRoles: [STAFF]
      }
    },
    {
      path: '/activity-report',
      name: 'ActivityReport',
      component: ActivityReport,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['ACTIVITY_REPORT']
      }
    },
    {
      path: '/documents',
      name: 'DocumentsCenterScreen',
      component: DocumentsCenterScreen,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['DOCUMENT_CENTER']
      }
    },
    {
      path: '/documents/new',
      name: 'DocumentCenterCreateScreen',
      component: DocumentCenterCreateScreen,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['DOCUMENT_CENTER'],
        minPermissionLevel: 2
      }
    },
    {
      path: '/documents/:id/edit',
      name: 'DocumentsCenterEditScreen',
      component: DocumentsCenterEditScreen,
      meta: {
        allowedRoles: [ STAFF, MANAGER ],
        permissions: ['DOCUMENT_CENTER']
      },
      props: true
    },
    {
      path: '/documents/:id/view',
      name: 'ViewDocumentScreen',
      component: ViewDocumentScreen,
      meta: {
        allowedRoles: [OSR, CLIENT, TEAM, STAFF, MANAGER],
        permissions: ['DOCUMENT_CENTER']
      },
      props: true
    },
    {
      path: '/',
      redirect: store.getters.defaultPage
    },
    {
      path: '*', // if page not found redirect to login page
      redirect: '/error/404'
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  let activeRole = store.state.clientSession.userRole
  let mfaChannel = store.state.clientSession.mfaChannel
  let isMFAValid = store.state.clientSession.isMfaValid

  let hasMFA = mfaChannel !== 'Undefined' && mfaChannel !== undefined && mfaChannel !== null && mfaChannel !== ''
  let isNotPublicRoute = to.matched.some(record => !record.meta.isPublic)
  let userLacksRoles = to.matched.some(
    record =>
      record.meta.allowedRoles &&
      !record.meta.allowedRoles.includes(activeRole)
  )

  let hasPermissions = false
  if (to.meta.permissions && activeRole.toLowerCase() !== 'client' && activeRole.toLowerCase() !== 'outside sales rep' && store.state.clientSession.permissions) {
    store.state.clientSession.permissions.map(permission => {
      if (to.meta.permissions.includes(permission.code) && permission.access !== 0) {
        if (to.meta.minPermissionLevel !== undefined && to.meta.minPermissionLevel !== null) {
          if (permission.access >= to.meta.minPermissionLevel) {
            hasPermissions = true
          } else if (!to.meta.minPermissionLevel) {
            hasPermissions = true
          }
        } else {
          hasPermissions = true
        }
      }
    })
  } else {
    hasPermissions = true
  }

  if (!hasPermissions) {
    next({ name: 'not-authorized', params: { code: 403 } })
  }

  if (isNotPublicRoute && !store.getters.isLoggedIn) {
    next({ name: 'Login', query: { fromUrl: to.fullPath } })
  } else if (userLacksRoles && store.getters.isLoggedIn) {
    next(store.getters.defaultPage)
  } else if ((to.name === 'Login') && store.getters.isLoggedIn) {
    next(store.getters.defaultPage)
  } else if (store.getters.isLoggedIn && (hasMFA && !isMFAValid) && to.name !== '2FAVerify') {
    next('/2fa-verify')
  } else if (store.getters.isLoggedIn && !hasMFA && !isMFAValid && to.name !== '2FASetup') {
    next('/2fa-setup')
  } else {
    next()
  }
})

export default router
