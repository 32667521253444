<template>
  <div>
    <div v-if="show" class="sidemenu-mask-dropdown-list carrier" :level="level" :open="true">
      <div
        class="sidemenu-mask-dropdown"

        @click="isOpen = !isOpen"
        @mouseover="ellipsis($event, true)"
        @mouseleave="ellipsis($event, false)"

        :id="item.code"
        :children="childCount"
        :ref="item.code"
      >
        <div class="tooltip-hover" v-tooltip.top-center="{ content: item.name, trigger: 'manual', boundariesElement: sidemenu }">
          <i :class="['status-icon fa fa-chevron-right', (isOpen ? 'open' : '')]"></i>
          <i v-if="!isOpen" class="fa fa-folder"></i>
          <i v-if="isOpen" class="fa fa-folder-open"></i>
          {{ item.name }}
        </div>
      </div>

      <div
        class="sidemenu-mask-dropdown-list"
        :data-controlled-by="item.code"
        :open="isOpen"
        :level="getNextLevel()"
        :type="item.code"
        :ref="item.code"
      >
         <div v-for="child in templates" :key="child.id">
           <MenuItem
            v-bind:item="child"
            :activeSite="activeSite"
            :activeUser="activeUser"
            :reportTypeCodes="reportTypeCodes"
            :level="getNextLevel()"
            :carriers="carriers"
            :open="isOpen"
            :reportCodeCounts="reportCodeCounts"
            :carrierCode="child.carrierCode"
            @years="onYearsEvent"
          />
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import Constants from '../../constants'
import _ from 'underscore'

export default {
  name: 'SidemenuCarrierItem',
  components: {
    'MenuItem': () => import('../MenuItem.vue')
  },
  props: {
    item: Object,
    level: Number,
    reportCodeCounts: Object,
    reportTypeCodes: Array,
    carriers: Array,
    activeSite: Object,
    activeUser: Object
  },
  data () {
    return {
      isOpen: false,
      sidemenu: document.querySelector('.sidemenu-mask'),
      documents: [],
      hasDocuments: false,
      childCount: 0,
      show: true,
      templates: [],
      code: null
    }
  },
  watch: {
    childCount (count) {
      if (count < 1) { // if no children
        this.show = false
        this.$emit('is-invalid')
      }
    }
  },
  mounted () {
    let that = this
    let templatesCopy = _.map(this.item.template, _.clone)
    const flattenedReportTypeCodes = this.reportCodeCounts ? this.reportCodeCounts.carriers.map(reportType => {
      if (reportType.carrierCode === this.item.code) {
        return reportType.typeCode
      }
    }) : []

    this.templates = templatesCopy.map(template => {
      delete template.templates
      return template
    }).filter(template => {
      let hasAtleastOne = false

      template.children.forEach(child => {
        if (flattenedReportTypeCodes.includes(child.code)) {
          hasAtleastOne = true
        }
      })

      return hasAtleastOne
    })

    this.childCount = this.templates.length

    this.templates.forEach(function (_child) {
      _child.level++
      _child.isCarrier = true
      that.appendIsDocument(_child)
    })

    let carrierCodes = this.reportCodeCounts.carriers.filter(function (_code) {
      if (_code.carrierCode === that.item.code) {
        return _code
      }
    })

    if (carrierCodes.length < 1) {
      this.$emit('is-invalid')
      this.show = false
    }
  },
  methods: {
    getNextLevel () {
      return this.level + 1
    },
    onYearsEvent (data) {
      let that = this
      Object.keys(data).forEach(function (key, value) {
        if (that.years[key]) {
          that.years[key] = [...that.years[key], ...data[key]]
        } else {
          that.years[key] = data[key]
        }
      })

      that.$forceUpdate()
    },
    handleEvent (action) {
      let amount = parseInt(action)
      if (amount === -1 && this.childCount > 0) {
        this.childCount--
      } else if (amount === 1 && this.childCount < 1) {
        this.childCount++
      }
    },
    appendIsDocument (child) {
      let that = this

      child.isDocument = true
      child.level++
      child.carrierCode = this.item.code
      child.carrierCodes = [this.item.code]
      child.children = _.map(child.children, _.clone)
      child.isCarrier = true

      if (child.children.length > 0) {
        child.children.forEach(function (_child) {
          that.appendIsDocument(_child)
        })
      }
    },
    navigate (menuItem) {
      this.$router.push({ name: menuItem.Route })
    },
    ellipsis: (event, mouseover) => {
      event.preventDefault()
      event.stopPropagation()
      const tooltip = event.currentTarget.querySelector('.tooltip-hover')

      if (
        tooltip &&
        (event.currentTarget.classList.toString() === 'sidemenu-mask-dropdown' ||
        event.currentTarget.classList.toString() === 'sidemenu-mask-item')
      ) {
        if (event.currentTarget.children[0].scrollWidth > event.currentTarget.children[0].offsetWidth && mouseover) {
          tooltip._tooltip.show()
        } else {
          tooltip._tooltip.hide()
        }
      }
    },
    getIcon (menuItem) {
      return Constants.menu.icon[menuItem.IconType].value
    }
  }
}
</script>
