<template>
  <div
    v-if="menuItem.isStatic"
    class="sidemenu-mask-dropdown-list"
    open="open"
    :level="nextLevel"
    :ref="menuItem.id"
    style="padding-left: 0rem !important"
  >
    <div v-for="report in menuItemReportsByNameDesc" :key="report.id">
      <div
        class="sidemenu-mask-item"
        :type="report.typeCode"
        @click.stop="($event) => getReport(report)"
        @mouseover="($event) => ellipsis($event, true)"
        @mouseleave="($event) => ellipsis($event, false)"
      >
        <div
          class="tooltip-hover"
          :open="false"
          v-tooltip="{
            content: report.displayName,
            trigger: 'manual'
          }"
        >
          <i class="fas fa-file item"></i>
          {{ report.displayName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'underscore'

export default {
  props: {
    reports: Object,
    menuItem: Object,
    level: Number
  },
  data () {
    return {
      menuItemReports: []
    }
  },
  watch: {
    reports (reports) {
      this.menuItemReports = reports
    }
  },
  computed: {
    nextLevel: function () {
      return this.level + 1
    },
    joinedmenuItemReportsFromAllYears () {
      let reports = []

      if (this.menuItemReports === null) {
        return []
      }

      Object.keys(this.reports).forEach((year) => {
        this.reports[year].forEach((report) => {
          reports.push(report)
        })
      })

      return reports
    },
    menuItemReportsByNameDesc () {
      const reportKeys = Object.keys(this.reports)

      if (reportKeys !== null && reportKeys.length > 0) {
        let nameParsedmenuItemReports = []
        this.joinedmenuItemReportsFromAllYears.forEach((report) => {
          nameParsedmenuItemReports.push({
            ...report,
            name: report.displayName
          })
        })

        return _.sortBy(nameParsedmenuItemReports, 'name').reverse()
      } else {
        return []
      }
    }
  },
  methods: {
    getReport (report) {
      if (!report.hasOwnProperty('carrierCode')) {
        this.$router.push({
          name: 'ViewReport',
          params: { id: report.id, report: report }
        })
      } else if (report.hasOwnProperty('carrierCode')) {
        if (report.isCarrier || report.isVendor) {
          report.path = !report.path.includes('/clients/')
            ? report.path.replace('clients/', '')
            : report.path
        }

        this.$router.push({
          name: 'ViewDocumentScreen',
          params: { id: report.id, document: report }
        })
      } else {
        window.open(report.path)
      }
    },
    ellipsis: (event, mouseover) => {
      event.preventDefault()
      event.stopPropagation()

      const tooltip = event.currentTarget.querySelector('.tooltip-hover')

      if (
        tooltip &&
        (event.currentTarget.classList.toString() ===
          'sidemenu-mask-dropdown' ||
          event.currentTarget.classList.toString() === 'sidemenu-mask-item')
      ) {
        if (
          event.currentTarget.children[0].scrollWidth >
            event.currentTarget.children[0].offsetWidth &&
          mouseover
        ) {
          tooltip._tooltip.show()
        } else {
          tooltip._tooltip.hide()
        }
      }
    }
  }
}
</script>
