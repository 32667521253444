<template>
  <div>
    <div
      class="sidemenu-mask-dropdown"

      @click="onFolderClick"
      @mouseover="$event =>  ellipsis($event, true)"
      @mouseleave="$event =>  ellipsis($event, false)"
    >
      <div
        class="tooltip-hover"
        :open="false"
        v-tooltip.top-center="tooltipConfig"
      >
        <i
          :class="['status-icon fa fa-chevron-right', isOpen ? 'open' : 'closed']"
        >
        </i>
        <i :class="getIcon(item.data) && !isFolder"></i>
        <i v-if="!isOpen" class="fa fa-folder"></i>
        <i v-if="isOpen" class="fa fa-folder-open"></i>
        {{ item.data.Label }}
      </div>
    </div>
  </div>
</template>

<script>
import Constants from '../constants'

export default {
  props: {
    item: Object,
    level: Number,
    open: Boolean,
    numChildren: Number,
    sidemenu: HTMLDivElement,
    tooltipConfig: Object
  },
  data () {
    return {
      isOpen: false
    }
  },
  mounted () {
    this.isOpen = this.open
  },
  watch: {
    open (isOpen) {
      this.isOpen = isOpen
    }
  },
  computed: {
    isFolder () {
      return Constants.menu.icon[this.item.data.IconType].value === 'fa fa-folder'
    }
  },
  methods: {
    onFolderClick (event) {
      event.preventDefault()
      event.stopPropagation()

      this.$emit('onOpenChange', !this.isOpen)
    },
    ellipsis: (event, mouseover) => {
      event.preventDefault()
      event.stopPropagation()
      const tooltip = event.currentTarget.querySelector('.tooltip-hover')

      if (
        tooltip &&
        (event.currentTarget.classList.toString() ===
          'sidemenu-mask-dropdown' ||
          event.currentTarget.classList.toString() === 'sidemenu-mask-item')
      ) {
        if (
          event.currentTarget.children[0].scrollWidth >
            event.currentTarget.children[0].offsetWidth &&
          mouseover
        ) {
          tooltip._tooltip.show()
        } else {
          tooltip._tooltip.hide()
        }
      }
    },
    getIcon (menuItem) {
      return Constants.menu.icon[menuItem.IconType].value
    }
  }
}
</script>
