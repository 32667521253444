export const updateSession = 'updateSession'
export const activeLayout = 'activeLayout'
export const isLoggedIn = 'isLoggedIn'
export const logout = 'logout'
export const defaultPage = 'defaultPage'
export const setActiveSite = 'setActiveSite'
export const activeSite = 'activeSite'
export const setSessionLength = 'setSessionLength'
export const updateSessionTimeout = 'updateSessionTimeout'
export const clearSessionTimeout = 'clearSessionTimeout'
export const isSessionExpirePromptOpen = 'isSessionExpirePromptOpen'
export const setIsSessionExpirePromptOpen = 'setIsSessionExpirePromptOpen'
export const updateServiceRequest = 'updateServiceRequest'
export const hasPermissions = 'hasPermissions'
