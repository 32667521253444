<template>
  <div id="app">
    <!-- <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.15.3/css/all.css" integrity="sha384-iKbFRxucmOHIcpWdX9NTZ5WETOPm0Goy0WmfyNcl52qSYtc2Buk0NCe6jU1sWWNB" crossorigin="anonymous"> -->
    <vue-headful :title="null" />
    <div id="app-area">
      <Header
        v-if="isLoggedIn && (![null, 'Login', '2FASetup', '2FAVerify', 'ChangePassword'].includes($route.name))"
        :activeLayout="this.activeLayout"
      />
      {{ isFullScreen }}
      <div
        :id="(isLoggedIn && ($route.name !== 'ChangePassword' && ![null, 'Login', '2FASetup', '2FAVerify'].includes($route.name))) ? (isFullScreen ? 'app-body-area-cp' : 'app-body-area') : 'app-body-area-cp'"
        :class="{'loggedout': !isLoggedIn && ($route.name !== 'ChangePassword' && ![null, 'Login', '2FASetup', '2FAVerify'].includes($route.name))}"
      >
        <Links v-if="(isLoggedIn && ($route.name !== 'ChangePassword' && ![null, 'Login', '2FASetup', '2FAVerify'].includes($route.name)))" @isOpen="sideNavisOpen = $event" :activeLayout="this.activeLayout" />
        <div
          :sideNavisOpen="!isFullScreen && sideNavisOpen && (isLoggedIn && ($route.name !== 'ChangePassword' && ![null, 'Login', '2FASetup', '2FAVerify'].includes($route.name)))"
          :id="(activeLayout == 'dev' || activeLayout == 'administrative') && ![null, 'Login', '2FASetup', '2FAVerify', 'ChangePassword'].includes($route.name) ? isFullScreen ? 'content-area-full' : 'content-area' : 'content-area-full'"
        >
          <Target />
        </div>
      </div>
      <Footer :activeLayout="this.activeLayout" />
    </div>
  </div>
</template>

<script>
import Links from './components/Links'
import Target from './components/Target'
import Header from './components/Header'
import Footer from './components/Footer'
import { mapGetters } from 'vuex'
import { activeLayout, isLoggedIn } from './vuex-actions'
import { bus } from './main.js'
import '@fortawesome/fontawesome-pro/css/all.css'

import '@progress/kendo-ui'
import '@progress/kendo-theme-default/dist/all.css'

export default {
  name: 'app',
  components: {
    Links,
    Target,
    Header,
    Footer
  },
  data: function () {
    return {
      sideNavisOpen: true,
      isFullScreen: false
    }
  },
  mounted () {
    bus.$on('full-screen-toggle', isFullScreen => {
      this.isFullScreen = isFullScreen
    })
  },
  computed: {
    ...mapGetters([activeLayout, isLoggedIn])
  },
  created () {},
  methods: {
    alert (object) {
      // console.log(object)
    }
  }
}
</script>

<style>

.admin-dropdown {
  width: 100%;
}

.admin-dropdown .admin-dropdown-trigger {
  width: 100%;
  display: flex;
  gap: .55rem;
  padding: .52rem;
  color: white !important;
  cursor: pointer;
}

.admin-dropdown input {
  display: none;
}

.admin-dropdown .admin-dropdown-content {
  border-top: 1px solid rgba(201, 201, 201, 0.37);
  border-bottom: 1px solid rgba(201, 201, 201, 0.37);
  width: 100%;
  display: none;
  background-color: #1f314d80;
  margin-left: -.6rem;
  /* margin-top: ; */
  width: calc(100% + 5.6rem);
  padding-left: .3rem;
  padding-block: .5rem;
}

.admin-dropdown .admin-dropdown-content .label {
  font-weight: 600;
  color: white;
  margin-left: -.6rem;
  /* margin-top: ; */
  width: calc(100% + 5.6rem);
  padding-left: 1.5rem;
  padding-block: .5rem;
 background-color: rgba(0, 0, 0, 0.356)
}
.admin-dropdown input:checked ~ .admin-dropdown-content {
  display: block;
}

.text-right {
  text-align: right!important;
}

[disabled="disabled"] {
  opacity: 0.25;
  cursor: not-allowed!important;
}

.disabled  {
  pointer-events: none;
  cursor: default;
  opacity: 0.5!important;
  display: flex!important;
}

html {
  font-size:15px;
}
#app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
}

#layout-switcher-area {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 42px;
  padding: 5px;
  background-color: #e69138;
}

.modal-background {
  z-index: 40!important;
}

#app-area {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

#app-body-area {
  position: absolute;
  top: 140px;
  right: 0;
  bottom: 0;
  padding: 5rem;
}

#app-body-area-cp {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.loggedout {
  top: 0 !Important;
}

select {
  z-index: 30;
}

#content-area {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 54px;
  left: 250px;
  /* overflow-y: auto; */
}

@media (max-width: 1000px) {
  .heading {
    width: 100vw;
    transform: translateX(-15%);
  }
}

[sideNavisOpen="false"] {
  width: calc(100vw - 50px)!important;
  transition: all .15s ease-in-out !important;
  left: 50px!important;
}

[isOpen="false"] ~ #content-area {
  transition: all .15s ease-in-out !important;
  width: calc(100vw - 50px)!important;
  left: 50px!important;
}

.page-wrapper {
  padding: 0rem 2rem!important;
}

.is-fullwidth {
  width: 100%!important;
}

#content-area-full {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 54px;
  left: 0;
  /* overflow-y: auto; */
}

.field.is-grouped .control {
  flex: 1 1 auto;
}

.column > .field {
  flex: 1 1 auto!important;
}

.column > .field > .control {
  width: 100%!important;
}

.column > .field.is-grouped .field, .column > .field.is-grouped .field > .control {
  width: 100%!important;
}

.text-is-accent {
  color: #7fb942 !important;
}

.button-group {
  display: flex;
  width: 100%;

  justify-content: flex-end;

  margin-bottom: 1rem;
}

.button-group > button {
  margin-left: 1rem;
}

.container-is-fs {
  background-color: #f5f5f5!important;
  z-index: 10000;
}

.nav-btn {
  background-color: #ebebeb!important;
}

/* Firefox */
[scroll], [scroll] * {
  scrollbar-width: none;
  scrollbar-color: #3C557D #EFEFEF;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Chrome, Edge and Safari */
[scroll]::-webkit-scrollbar, [scroll] *::-webkit-scrollbar {
  width: 0!important;
  display: none;
}

.center-it {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 2rem - 52px - 86px - 92px - 1rem)!important;
}
</style>
<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// --catalinablue: #1F314D;
// --prussianblue: #002E4C;
// --bluebayoux: #59657A;
// --eclipse: #3A3A3A;
// --jumbo: #8C8C88;
// --bombay: #AAAAA9;
// --whitesmoke: #F1F1F1;
// --apple: #7FB942;
// --california: #E69138;

$accent: #7fb942;
$gray: #3a3a3a;
$fadedblue: #59657a;
$california: #e69138;
$jumbo: #8c8c88;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black
  ),
  "black": (
    $black,
    $white
  ),
  "light": (
    $light,
    $light-invert
  ),
  "dark": (
    $jumbo,
    findColorInvert($jumbo)
  ),
  "primary": (
    #1f314d,
    findColorInvert(#1f314d)
  ),
  "info": (
    $info,
    $info-invert
  ),
  "success": (
    $success,
    $success-invert
  ),
  "warning": (
    $california,
    findColorInvert($california)
  ),
  "danger": (
    $danger,
    $danger-invert
  ),
  "accent": (
    $accent,
    findColorInvert($accent)
  ),
  "gray": (
    $gray,
    $accent
  ),
  "fadedblue": (
    $fadedblue,
    findColorInvert($fadedblue)
  )
);

// Links
$link: $accent;
$link-invert: findColorInvert($accent);
$link-focus-border: $accent;
$link-hover: $california;

$button-hover-color: $link-hover;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body, * {
  font-family: "Poppins", Helvetica, sans-serif;
}

tbody tr, .menuItemRow {
  font-size: 14px!important;
}

.label {
  font-weight: normal !important;
}

.button.is-accent:hover {
  background-color: $link-hover;
}

// Add stock bg colors (see: https://github.com/jgthms/bulma/issues/1525)
@each $name, $pair in $colors {
  $color: nth($pair, 1);
  $color-invert: nth($pair, 2);
  .has-bg-#{$name} {
    color: $color-invert !important;
    background-color: $color !important;
    .title,
    .subtitle,
    strong {
      color: currentColor;
    }
  }
}

.is-monospaced, .is-monospaced * {
  font-family: 'Roboto Mono', monospace!important;
  font-weight: 400;
}

.semi-bold {
  font-weight: 800!important;
}

.font-italic {
  font-style: italic!important;
}

.magnify {
  animation: magnify 1s infinite!important;
  display: flex;
}

@keyframes magnify {
  0% {
    opacity: .5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .5;
  }
}

.text-warning {
  color: #eaae5e!important;
}

.text-grey {
  color: #7fb942!important;
}

.text-info {
  color: #3d567d!important;
}

.fade {
  animation: magnify 1s infinite!important;
  /* opacity: .5!important; */
}

.ellipsis {
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  white-space: nowrap!important;
}
</style>
