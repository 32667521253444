/**
 * This file defines globa constants which should be importable.
 */
const Constants = {
  menu: {
    type: {
      'group': 1,
      'reports': 2,
      'link': 3,
      'dynamic Carrier': 4,
      'dyanmic Plans': 5,
      'vendor': 6
    },
    icon: {
      1: {
        name: 'Folder',
        value: 'fa fa-folder'
      },
      2: {
        name: 'Document',
        value: 'fa fa-file'
      },
      3: {
        name: 'Certify',
        value: 'fas fa-certificate'
      },
      4: {
        name: 'Institution',
        value: 'fas fa-university'
      },
      5: {
        name: 'Calendar',
        value: 'far fa-calendar'
      },
      6: {
        name: 'Calendar Day',
        value: 'fas fa-calendar-day'
      },
      7: {
        name: 'Event',
        value: 'far fa-calendar-check'
      },
      8: {
        name: 'Service',
        value: 'fas fa-portrait'
      },
      9: {
        name: 'People',
        value: 'fas fa-user-friends'
      },
      10: {
        name: 'Issue',
        value: 'fas fa-exclamation-circle'
      }
    },
    itemTypes: {
      'group': 1,
      'reports': 2,
      'link': 3,
      'carrier': 4,
      'dyanmic Plans': 5,
      'vendor': 6
    }
  },
  reports: {
    monthly_report: {
      1: {
        code: 'MBR'
      },
      2: {
        code: 'MCR'
      }
    }
  },
  documents: {
    default_user_upload_type: 'USR'
  }
}

export default Constants
